import { Amplify, ResourcesConfig } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";

import { getMyUserId } from "src/auth/api/getMyUserId";
import { getCurrentUserId, getTokenForCurrentUser, setTokens } from "src/auth/utils/userToken";

import { isVercelOrigin } from "./isVercelOrigin";

export async function refreshCognitoToken(): Promise<string | null> {
  const response = await fetchAuthSession({ forceRefresh: true });
  const token = response.tokens?.accessToken?.toString();
  if (token === null || token === undefined || token === "") {
    return null;
  }
  const userId = getCurrentUserId();
  if (userId === null) {
    const userIdResponse = await getMyUserId({
      customAuthToken: response.tokens?.accessToken?.toString() ?? "",
    });
    const userId = await userIdResponse.json();
    setTokens(userId, response?.tokens?.accessToken.toString() ?? "");
  } else {
    setTokens(userId, response?.tokens?.accessToken.toString() ?? "");
  }

  return getTokenForCurrentUser();
}

function getCognitoConfig(): ResourcesConfig {
  return {
    Auth: {
      Cognito: {
        userPoolClientId: process.env.NEXT_PUBLIC_COGNITO_POOL_CLIENT_ID ?? "",
        userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID ?? "",
        loginWith: {
          oauth: {
            domain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN ?? "",
            scopes: ["email", "openid"],
            redirectSignIn: [
              isVercelOrigin(window?.location?.origin)
                ? process.env.NEXT_PUBLIC_VERCEL_REDIRECT_URL || ""
                : window?.location?.origin || "",
            ],
            redirectSignOut: [window?.location?.origin ?? ""],
            responseType: "code",
          },
        },
      },
    },
  };
}

export function initializeCognito() {
  if (
    typeof window !== "undefined" &&
    process.env.NEXT_PUBLIC_COGNITO_POOL_CLIENT_ID &&
    process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID &&
    process.env.NEXT_PUBLIC_COGNITO_DOMAIN
  ) {
    Amplify.configure(getCognitoConfig());
  }
}

// TODO: Support controlled async search
import { colors, Heading } from "@ch/ui/pro";
import { DebouncedFunc } from "lodash";
import debounce from "lodash/debounce";
import React, { useState, useRef, useEffect, useMemo, LegacyRef } from "react";
import { TouchableOpacity, View, TextInput, FlatList } from "react-native";

import { noop } from "src/common/utils";
import { useHover } from "src/foundations/hooks/useHover";
import { tailwind } from "src/foundations/styles";
import { Icon, Paragraph } from "src/foundations/ui";
// TODO: Use Popover for Select so the option can be visible when displayed out in the wild.
import Popover from "src/ui/components/Popover";

import { Option, getSelected, Item, BaseSelectProps } from "./utils";

// in case we want to extract this later to a set of common debounce values,
// this will be easier to replace
export const DEBOUNCE_TEXTINPUT = 250;

export default function BaseSelect(props: BaseSelectProps) {
  const {
    deselectValueLabel,
    isOpen,
    items,
    onClose,
    onOpen,
    onValueChange,
    // TODO: Support controlled async search
    useLocalSearch = true,
    // TODO: Support controlled async search
    onSearchTextChange = noop,
    // TODO: Support controlled async search
    keyExtractor,
    placeholder,
    style,
    size,
    value,
    searchable,
    selectType,
    testID,
    accessibilityLabelledBy,
    accessibilityLabel,
    disabled,
  } = props;
  const hasItems = items && items.length;
  const isHeadingSelect = selectType === "heading";
  // creates an entry in `items` for a `null` value, which would reset the
  // passed in `value` to null, resetting the list to its initial default state
  // this is memoized to prevent unneeded calculations of this list
  const itemsWithMaybeDeselect: Array<Item> = useMemo(
    () =>
      deselectValueLabel && hasItems
        ? [{ label: deselectValueLabel, value: null }, ...items]
        : items
        ? items
        : [],
    [deselectValueLabel, hasItems, items]
  );

  const [ref, hovered] = useHover();

  const [filterInputText, setFilterTextInput] = useState("");
  // TODO: Support controlled asnyc search. Updated this line to empty array as it will be set each
  // time by useEffect or local search.
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const inputRef = useRef<DebouncedFunc<(query: string) => void>>();
  const [dropdownWidth, setDropdownWidth] = useState(0);

  const inputCompRef = useRef<TextInput>(null);
  useEffect(() => {
    if (isOpen) inputCompRef?.current?.focus();
  }, [isOpen, inputCompRef]);

  // TODO: Support controlled async search. Added effect to update filter items after initial render
  // for case when component fetches options
  useEffect(() => {
    setFilteredItems(itemsWithMaybeDeselect);
  }, [itemsWithMaybeDeselect]);

  // Setup handlers for closing dropdown on offclick or escape
  const selectRef = useRef<View>(null);
  // TODO: This is handled by the Popover
  // useDropdownCloseHandlers(selectRef, () => {
  //   if (isOpen) onClose();
  // });

  /**
   * Setup text filtering in the dropdown list.
   * 1. setup a filter function that will filter the results using the query
   * 2. wrap that filter in a debounce so it doesn't fire rapidly/unnecessarily
   *    and attach that debounced filter to a ref to keep it around.
   *    NOTE: this happens in useEffect with [] to make sure it happens only once
   * 3. Call the debounced filter any time the text gets updated in the text box
   */
  useEffect(() => {
    const updateFilteredResults = (query: string) => {
      setFilteredItems(
        !!query
          ? filteredItems.filter((item) =>
              item.label.toLocaleLowerCase().includes(query.toLowerCase())
            )
          : itemsWithMaybeDeselect
      );
    };
    // initialize debounce function to search once user has stopped typing every 1/4 second
    inputRef.current = debounce(updateFilteredResults, DEBOUNCE_TEXTINPUT);
  }, [filteredItems, itemsWithMaybeDeselect]);

  const onChangeFilter = (filter: string) => {
    setFilterTextInput(filter);
    // TODO: Support controlled async search. Added surrounding if statement only
    if (useLocalSearch) {
      inputRef?.current && inputRef.current(filter); // use the ref to call the debounced filter fn
    }
    // TODO: Support controlled async search. Added filter text change event
    onSearchTextChange(filter);
  };

  const onClickHeader = () => {
    onChangeFilter(""); // clear the filter text when opening/closing picker
    isOpen ? onClose() : onOpen(); // close/open the dropdown
  };

  if (!isHeadingSelect && !hasItems) {
    // TODO: This causes test output pollution
    // console.warn("Cannot render Select without items");
    // return null;
  }

  const selected = getSelected(items, value);

  // Function to render a single row of the FlatList
  const renderItem = ({ item }: { item: Item }) => (
    <TouchableOpacity
      // close the dropdown without calling onValueChange if selecting the same thing
      onPress={() =>
        selected && selected.value === item.value ? onClose() : onValueChange(item.value, item)
      }
      // above ^^ in onPress handler included items as part of onValueChange invocation
      style={tailwind("w-full")}
      // @ts-expect-error TS2769: No overload matches this call.
      accessibilityRole="option"
    >
      <Option
        label={item.label}
        value={item.value}
        selected={Boolean(selected && item.value === selected.value)}
      />
    </TouchableOpacity>
  );

  const selectHeightStyle = useMemo(() => {
    switch (size) {
      case "sm":
        return tailwind("h-8");
      case "md":
        return tailwind("h-10");
      case "lg":
        return { height: "52px" };
    }
    return undefined;
  }, []);

  return (
    <View style={[tailwind("items-start z-dropdown"), style]}>
      {/* SelectRef needs to be passed to this view, since this
      is the one that doesn't automatically stretch to fill row space */}
      {/* TODO: Support these widths */}
      {/* <View style={tailwind("items-start")} ref={selectRef}> */}
      <View
        style={[tailwind("items-start"), { width: props.width === "full" ? "100%" : "auto" }]}
        ref={selectRef}
      >
        {/* If there are no items passed in, we don't want the touchable 
        header for heading select. Since we return null for normal Selects with 
        no items, we don't NEED the isHeadingSelect check, but leaving it for clarity*/}
        {!hasItems && isHeadingSelect ? (
          <View style={tailwind("flex-row items-center rounded-lg overflow-hidden px-2 py-1")}>
            <Heading level={2} accessibilityLevel={1} weight="bold" legacyStyle={tailwind("mr-4")}>
              {selected ? selected.label : placeholder}
            </Heading>
          </View>
        ) : (
          <TouchableOpacity
            ref={ref as LegacyRef<TouchableOpacity>}
            accessibilityRole="combobox"
            // @ts-expect-error TS2769: No overload matches this call.
            accessibilityHasPopup="listbox"
            accessibilityHint="Opens a dropdown picker"
            accessibilityState={{ expanded: isOpen }}
            onPress={onClickHeader}
            // Normal selects get a border, gray when inactive, green when active
            style={[
              tailwind(
                `flex-row items-center rounded-lg overflow-hidden justify-between ${
                  isHeadingSelect ? "px-2 py-1" : "p-4 bg-white border border-gray-100"
                  // TODO: Don't make border green by default. This should be applied by a
                  // higher-level component than BaseSelect. E.g. <FilterDropdown /> that styles the
                  // BaseSelect.
                  // } ${!isHeadingSelect && !!selected ? "border-green-dark" : ""}`
                }`
              ),
              selectHeightStyle,
              // TODO: 300 is too wide for our use case (page size control).
              // { minWidth: 300 },
              // TODO: Support these widths
              { width: props.width === "full" ? "100%" : "auto" },
              { backgroundColor: disabled ? colors.gray[100] : colors.white },
            ]}
            onLayout={(e) => setDropdownWidth(e.nativeEvent.layout.width)}
            testID={testID || "select"}
            accessibilityLabelledBy={accessibilityLabelledBy}
            accessibilityLabel={accessibilityLabel}
            disabled={disabled}
          >
            {isHeadingSelect ? (
              <Heading
                level={2}
                accessibilityLevel={1}
                weight="bold"
                legacyStyle={tailwind("mr-4")}
              >
                {selected ? selected.label : placeholder}
              </Heading>
            ) : (
              <View style={tailwind("flex-row")}>
                {/* TODO: Support optional icon. */}
                {/* <Icon name={props.icon} size={16} /> */}
                {props.icon ? <Icon name={props.icon} size={16} /> : null}
                <Paragraph
                  level={2}
                  accessibilityRole="label"
                  // TODO: Support optional icon.
                  // TODO: Text coloring should be done via an app-specific theme or other config.
                  // The default should be neutral.
                  // style={[tailwind("ml-3 mr-4 text-gray-400"), { lineHeight: 14 }]}
                  style={[
                    tailwind("mr-4"),
                    {
                      lineHeight: 14,
                      color: disabled
                        ? colors.gray[400]
                        : selected
                        ? colors.black
                        : colors.gray[600],
                    },
                  ]}
                >
                  {selected ? selected.label : placeholder}
                </Paragraph>
              </View>
            )}
            <View style={[{ transform: [{ rotate: isOpen ? "180deg" : "0deg" }] }]}>
              <Icon name="chevron-down" size={isHeadingSelect ? 16 : 9} />
            </View>
            {/* gray background on hover/open HeadingSelect */}
            {(isHeadingSelect && isOpen) ||
              (hovered && (
                <View
                  style={[
                    tailwind("bg-gray-100 opacity-60 absolute left-0 right-0 top-0 bottom-0"),
                    { zIndex: -1 },
                  ]}
                />
              ))}
          </TouchableOpacity>
        )}
      </View>

      {/* TODO: Display in a Popover instead of an absolutely-positioned div, which doesn't work
      well if there's nesting whatsoever) */}
      {/* {isOpen && !!hasItems && ( */}
      <Popover
        anchor={selectRef}
        nativeID="test"
        isOpen={useLocalSearch ? isOpen && !!hasItems : isOpen}
        onClose={props.onClose}
        // TODO set the placement to bottom left
        placement="bottom left"
      >
        <View
          testID="select-dropdown"
          style={[
            tailwind(
              // TODO: Some of these styles are unnecessary when rendered inside a Popover
              // "bg-white rounded-lg px-6 py-4 shadow shadow-size-s flex-1 items-center flex-col p-0 absolute top-full mt-2"
              "rounded-lg flex-1 items-center flex-col top-full"
            ),
            // TODO reduce the maxHeight to prevent the dropdown cover much of the screen
            { maxHeight: 250, width: dropdownWidth },
            isHeadingSelect ? { maxWidth: 620, minWidth: 375 } : {},
          ]}
          // @ts-expect-error TS2769: No overload matches this call.
          accessibilityRole="listbox"
        >
          {searchable && (
            <View style={tailwind("p-3 w-full mb-2")}>
              <TextInput
                onChangeText={onChangeFilter}
                value={filterInputText}
                placeholder="Search"
                accessibilityLabel="Search"
                style={tailwind("w-full h-12 p-3 items-center border-gray-100 border rounded-lg")}
                ref={inputCompRef}
                testID="select-textinput"
              />
            </View>
          )}
          <FlatList
            data={filteredItems}
            renderItem={renderItem}
            // TODO: Support controlled async search.
            keyExtractor={keyExtractor ?? ((item) => item.value || "")}
            style={tailwind("w-full")}
            // Adding this condition to show all the items in the list when running tests
            initialNumToRender={process.env.NODE_ENV === "test" ? 100 : 10}
          />
        </View>
      </Popover>
    </View>
  );
}

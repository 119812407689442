import {VisitorInput} from "./types";

type IdState = {
  get(): string | undefined;
  set(id: string): void;
};

type VisitorInternalState = {
  visitorId: IdState;
  visitId: IdState;
};

export type VisitorStateAPI = Readonly<{
  /**
   * Sets the visit and visitor IDs in memory and persists them.
   * Also calls onSetVisit if it was provided in options.
   */
  setVisit(input: VisitorInput): Promise<void>;
  /**
   * The visitor ID, if it has been set.
   */
  visitorId: string | undefined;
  /**
   * The visit ID, if it has been set.
   */
  visitId: string | undefined;
}>;

function idStateFactory(): IdState {
  let id: string | undefined;
  return {
    get() {
      return id;
    },
    set(newId) {
      id = newId;
    },
  };
}

export default function createVisitorStateAPI(): VisitorStateAPI {
  const idState: VisitorInternalState = {
    visitorId: idStateFactory(),
    visitId: idStateFactory(),
  };

  return {
    async setVisit({visitorId, visitId}) {
      idState.visitorId.set(visitorId);
      idState.visitId.set(visitId);
    },
    get visitorId() {
      return idState.visitorId.get();
    },
    get visitId() {
      return idState.visitId.get();
    },
  };
}

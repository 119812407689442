// TODO: Add this icon in foundations.
import Svg, { Path } from "react-native-svg";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from "./";

export default function TrashcanIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;

  return (
    <Svg width={props.size} height={props.size} fill="none" viewBox="0 0 16 16">
      <Path
        stroke={color ?? colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2 4h12M5.33 4V2.67c0-.74.6-1.34 1.34-1.34h2.66c.74 0 1.34.6 1.34 1.34V4m2 0v9.33c0 .74-.6 1.34-1.34 1.34H4.67c-.74 0-1.34-.6-1.34-1.34V4Zm-6 3.33v4m2.66-4v4"
      />
    </Svg>
  );
}

import { logError } from "./reporting";

export async function decodeUrlData(encodedData?: string): Promise<unknown | null> {
  if (encodedData === undefined) return null;

  if (!("CompressionStream" in window)) {
    try {
      return JSON.parse(decodeURIComponent(encodedData));
    } catch (err) {
      logError(err, {
        customMessage: `Unable to decode url data`,
        extraData: { encodedData },
      });
      // if data is invalid we return null
      return null;
    }
  }

  return decompressAndDecodeUrlData(encodedData);
}

async function decompressAndDecodeUrlData(encodedData: string): Promise<unknown | null> {
  try {
    const byteArray = Uint8Array.from(
      atob(encodedData)
        .split("")
        .map((char) => char.charCodeAt(0))
    );

    const stream = new Blob([byteArray], {
      type: "application/json",
    }).stream();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const decompressedReadableStream = stream.pipeThrough(new DecompressionStream("gzip"));

    const resp = new Response(decompressedReadableStream);
    const blob = await resp.blob();

    const data = JSON.parse(await blob.text());

    return data;
  } catch (err) {
    logError(err, {
      customMessage: `Unable to decode url data`,
      extraData: { encodedData },
    });
    // if data is invalid we return null
    return null;
  }
}

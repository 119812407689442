import * as React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Color } from "react-native-svg";
import Svg, { Rect, Defs, Stop, LinearGradient as NativeLinearGradient } from "react-native-svg";

import colors from "./colors.json";

export type LinearGradientProps = {
  style?: StyleProp<ViewStyle>;
  height: number;
  width: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  stopColor?: Color;
  stopOpacity?: number;
  offset?: number;
  useFade?: boolean;
};

export default function LinearGradient({
  style,
  height,
  width,
  x1,
  x2,
  y1,
  y2,
  stopColor,
  stopOpacity,
  offset,
}: LinearGradientProps) {
  const viewBox = `0 0 ${width} ${height}`;
  return (
    <View style={style} testID="linear-gradient">
      <Svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        preserveAspectRatio="xMinYMin slice"
      >
        <Rect width={width} height={height} fill="url(#paint0_linear)" />
        <Defs>
          <NativeLinearGradient
            id="paint0_linear"
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor={stopColor || colors.white.DEFAULT} stopOpacity={stopOpacity || 0} />
            <Stop offset={offset || 1} stopColor={stopColor || colors.white.DEFAULT} />
          </NativeLinearGradient>
        </Defs>
      </Svg>
    </View>
  );
}

import { Platform } from "react-native";

import { CarbonHeaders, placeholderPracticeId } from "src/auth/constants";
import { getLocalPracticeId } from "src/auth/utils/practice";
import { getTokenForCurrentUser } from "src/auth/utils/userToken";

import VisitorAPI from "./visitor";

export type FetchingOptions = {
  customAuthToken?: string;
};

export function createFetcher(baseUrl: string) {
  return async function fetcher(
    reqInfo: RequestInfo,
    init: RequestInit = {},
    options?: FetchingOptions
  ): Promise<Response> {
    const token = options?.customAuthToken ?? getTokenForCurrentUser();
    const { visitId, visitorId } = VisitorAPI;

    return await fetch(
      typeof reqInfo === "string"
        ? `${baseUrl}${reqInfo}`
        : { ...reqInfo, url: `${baseUrl}${reqInfo.url}` },
      {
        ...init,
        headers: {
          ...init.headers,
          "content-type": "application/json",
          [CarbonHeaders.PLATFORM_TYPE]: Platform.OS,
          [CarbonHeaders.APP_TYPE]: "billingHub",
          [CarbonHeaders.APP_VERSION]: process.env.NEXT_PUBLIC_GITHUB_RELEASE_VERSION ?? "0.0.0",
          ...(token && { [CarbonHeaders.AUTHORIZATION]: `Bearer ${token}` }),
          ...(visitId && { [CarbonHeaders.VISIT_ID]: visitId }),
          ...(visitorId && { [CarbonHeaders.VISITOR_ID]: visitorId }),
          ...(getLocalPracticeId() !== placeholderPracticeId && {
            [CarbonHeaders.PRACTICE_ID]: getLocalPracticeId() ?? undefined,
          }),
        },
      }
    );
  };
}

export const monolithFetcher = createFetcher(process.env.NEXT_PUBLIC_MONOLITH_URI + "/hib");
export const graphQLFetcher = createFetcher(process.env.NEXT_PUBLIC_GRAPHQL_URI || "");

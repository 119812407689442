import { createVisitorAPI } from "@ch/data/visitor";

/**
 * A singleton that manages the visitor and visit IDs,
 * maintaining state and persistence.
 */
const VisitorAPI = createVisitorAPI({
  isWeb: true,
});

export default VisitorAPI;

import isNil from "lodash/isNil";
import repeat from "lodash/repeat";

import { Maybe } from "src/graphql/types/__generated__/type";

export function maskEmail(email?: Maybe<string>) {
  if (isNil(email) || email.length === 0 || !email.includes("@")) {
    return email;
  }

  const [identifier, domain] = email.split("@");
  const securedIdentifier = repeat("*", identifier.length);

  return `${securedIdentifier}@${domain}`;
}

export function maskString(s?: Maybe<string>) {
  return s && s.length > 0 ? `${s[0]}${repeat("*", s.length - 1)}` : "";
}

const unmaskedPhoneNumberLength = 4;

export function maskPhoneNumber(phoneNumber?: Maybe<string>) {
  if (isNil(phoneNumber) || phoneNumber.length <= unmaskedPhoneNumberLength) {
    return phoneNumber;
  }

  const cutOffPoint = phoneNumber.length - unmaskedPhoneNumberLength;
  const masked = phoneNumber.slice(0, cutOffPoint).replace(/\d/g, "*");
  const unmasked = phoneNumber.slice(cutOffPoint);
  return `${masked}${unmasked}`;
}

export function maskDate(date?: Maybe<string>) {
  if (isNil(date) || date.length <= unmaskedPhoneNumberLength) {
    return date;
  }

  return date.replace(/\d/g, "*");
}

import { TokenResponse } from "@okta/okta-auth-js";
import { useRouter } from "next/router";
import { useCallback } from "react";

import {
  getOktaAuthClient,
  isOktaFlowTimedOutError,
  isPopupBlockedOrClosed,
} from "src/auth/utils/okta";
import {
  clearCurrentUserTokens,
  getCurrentUserId,
  getRefreshTokenForCurrentUser,
  getTokenForCurrentUser,
} from "src/auth/utils/userToken";
import { logError } from "src/common/utils/reporting";

import { AuthProviderDispatch } from "./authProviderStore";

type UseOktaProps = {
  dispatch: AuthProviderDispatch;
  onStartPracticeInitFlow: (props: {
    accessToken?: string;
    mountedRef: { mounted: boolean };
    restoringExistingSession: boolean;
    refreshToken?: string;
  }) => Promise<void>;
  onSignOut: () => void;
};
export type UseOktaData = {
  /**
   * Kick off the login process with Okta. Stores access and refresh tokens for future usage.
   *
   * Handles errors internally and doesn't throw them to consumer.
   */
  signInWithOkta: () => Promise<void>;
  signOutWithOkta: () => Promise<void>;
  tryRestoreOktaSession: () => Promise<void>;
};

function useOkta(props: UseOktaProps): UseOktaData {
  const { dispatch, onSignOut, onStartPracticeInitFlow } = props;
  const router = useRouter();

  const signInWithOkta = useCallback(
    async function signInWithOkta() {
      try {
        dispatch({ type: "LOGIN_STARTED" });

        const oktaClient = getOktaAuthClient();
        const loginResponse: TokenResponse = await oktaClient.token.getWithPopup({
          popupTitle: "Log in with Okta",
        });
        const accessToken = loginResponse.tokens.accessToken?.accessToken;
        const refreshToken = loginResponse.tokens.refreshToken?.refreshToken;

        if (accessToken === undefined) {
          dispatch({
            type: "LOGIN_FAILED",
            payload: { error: new Error("Okta authentication failed. No access token returned.") },
          });
          return;
        }
        await onStartPracticeInitFlow({
          accessToken,
          mountedRef: { mounted: true },
          restoringExistingSession: false,
          refreshToken,
        });
      } catch (error) {
        dispatch({
          type: "ERROR",
          payload: {
            error: isPopupBlockedOrClosed(error)
              ? new Error("Okta popup is blocked or closed")
              : isOktaFlowTimedOutError(error)
              ? new Error("Okta flow has timed out")
              : error,
          },
        });
      }
    },
    [dispatch, onStartPracticeInitFlow]
  );

  const signOutWithOkta = useCallback(
    async function signOutWithOkta() {
      onSignOut();
      clearCurrentUserTokens();
      try {
        const client = getOktaAuthClient();
        await client.signOut();
      } catch (error) {
        const userId = getCurrentUserId();
        logError(error, { customMessage: `Unable to logout user ${userId} with Okta completely` });
        // If okta failed we won't get the automatic redirect. In this case want to manually direct user out since we
        // can't really do anything but investigate.
        router.push("/sign-in");
      }
      dispatch({ type: "LOGOUT_DONE" });
    },
    [dispatch, onSignOut, router]
  );

  const tryRestoreOktaSession = useCallback(
    async function tryRestoreOktaSession() {
      const token = getTokenForCurrentUser();
      const refreshToken = getRefreshTokenForCurrentUser();
      await onStartPracticeInitFlow({
        accessToken: token ?? undefined,
        refreshToken: refreshToken ?? undefined,
        mountedRef: { mounted: true },
        restoringExistingSession: true,
      });
    },
    [onStartPracticeInitFlow]
  );

  return {
    signInWithOkta,
    signOutWithOkta,
    tryRestoreOktaSession,
  };
}

export default useOkta;

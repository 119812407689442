import { Dispatch } from "react";

export type AuthStatus =
  | "unknown"
  | "authenticated"
  | "unauthenticated"
  | "needTwoFactorAuth"
  | "needPasswordSetup"
  | "needPracticeDoctorSelection"
  | "needPasswordReset"
  | "error"
  | "loggedOut";

export type AuthProviderState = {
  status: AuthStatus;
  userId?: string;
  practiceDoctorSelectorUserId?: string;
  twoFactorDestination?: string;
  twoFactorMedium?: string;
  accessToken?: string;
  refreshToken?: string;
  error?: Error;
};

export const authProviderDefaultState: AuthProviderState = {
  userId: undefined,
  practiceDoctorSelectorUserId: undefined,
  status: "unknown",
  error: undefined,
  twoFactorDestination: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  twoFactorMedium: undefined,
};

// TODO: Action is any because we're not defining the action types and the actions are fired inline.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AuthProviderAction = any;
export type AuthProviderDispatch = Dispatch<AuthProviderAction>;

export function authProviderStoreReducer(
  state: AuthProviderState,
  action: AuthProviderAction
): AuthProviderState {
  switch (action.type) {
    case "GET_STORED_TOKEN_FAILED": {
      return { ...state, status: "unauthenticated", userId: undefined };
    }
    case "GET_USER_ID_FAILED": {
      return { ...state, status: "unauthenticated", userId: undefined };
    }
    case "GET_USER_ID_DONE": {
      return { ...state, status: "authenticated", userId: action.payload.userId };
    }
    case "LOGIN_STARTED": {
      return { ...state, userId: undefined, error: undefined };
    }
    case "LOGIN_FAILED": {
      return { ...state, status: "unauthenticated", error: action.payload.error };
    }
    case "2FA_NEEDED": {
      return {
        ...state,
        status: "needTwoFactorAuth",
        twoFactorMedium: action.payload.twoFactorMedium,
        twoFactorDestination: action.payload.twoFactorDestination,
        error: undefined,
      };
    }
    case "2FA_FAILED": {
      return {
        ...state,
        status: "needTwoFactorAuth",
        error: action.payload.error,
      };
    }

    case "2FA_CANCELLED": {
      return {
        ...state,
        status: "unauthenticated",
        error: undefined,
        twoFactorMedium: undefined,
        twoFactorDestination: undefined,
      };
    }
    case "PASSWORD_SETUP_NEEDED": {
      return { ...state, status: "needPasswordSetup", error: undefined };
    }
    case "PASSWORD_SETUP_FAILED": {
      return { ...state, status: "needPasswordSetup", error: action.payload.error };
    }
    case "PASSWORD_SETUP_CANCELLED": {
      return { ...state, status: "unauthenticated", error: undefined };
    }

    case "PASSWORD_RESET_NEEDED": {
      return {
        ...state,
        status: "needPasswordReset",
        twoFactorMedium: action.payload.twoFactorMedium,
        twoFactorDestination: action.payload.twoFactorDestination,
        error: undefined,
      };
    }
    case "PASSWORD_RESET_FAILED": {
      return {
        ...state,
        status: "needPasswordReset",
        error: action.payload.error,
      };
    }
    case "PASSWORD_RESET_CANCELLED": {
      return {
        ...state,
        status: "unauthenticated",
        twoFactorMedium: undefined,
        twoFactorDestination: undefined,
        error: undefined,
      };
    }
    case "PRACTICE_DOCTOR_SELECTION_NEEDED": {
      return {
        ...state,
        status: "needPracticeDoctorSelection",
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        practiceDoctorSelectorUserId: action.payload.practiceDoctorSelectorUserId,
        error: undefined,
      };
    }
    case "PRACTICE_DOCTOR_SELECTION_FAILED": {
      return { ...state, status: "needPracticeDoctorSelection", error: action.payload.error };
    }
    case "PRACTICE_DOCTOR_SELECTION_CANCELLED": {
      return {
        ...state,
        status: "unauthenticated",
        accessToken: undefined,
        refreshToken: undefined,
        practiceDoctorSelectorUserId: undefined,
        error: undefined,
      };
    }
    case "LOGIN_DONE": {
      return {
        ...state,
        status: "authenticated",
        userId: action.payload.userId,
        twoFactorMedium: undefined,
        accessToken: undefined,
        refreshToken: undefined,
        practiceDoctorSelectorUserId: undefined,
        twoFactorDestination: undefined,
        error: undefined,
      };
    }
    case "ERROR": {
      return { ...state, status: "error", error: action.payload.error };
    }
    case "LOGOUT_DONE": {
      return { ...state, status: "loggedOut" };
    }
    default:
      return state;
  }
}

import Svg, { Path } from "react-native-svg";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export const ChevronRightIcon: React.FC<IconProps> = (props) => {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg height={props.size} width={props.size} fill="none" viewBox="0 0 16 16">
      <Path
        d="m5 2 5.8795 5.71733c.0382.0371.0685.08117.0892.12967s.0313.10049.0313.153-.0106.1045-.0313.153-.051.09256-.0892.12967l-5.8795 5.71733"
        stroke={color ?? colors.gray[800]}
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </Svg>
  );
};

export default ChevronRightIcon;

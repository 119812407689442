import { memo } from "react";
import Svg, { Path } from "react-native-svg";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

const ChevronUpDown: React.FC<IconProps> = (props) => {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.25C10.1989 2.25 10.3897 2.32902 10.5303 2.46967L14.5303 6.46967C14.8232 6.76256 14.8232 7.23744 14.5303 7.53033C14.2374 7.82322 13.7626 7.82322 13.4697 7.53033L10 4.06066L6.53033 7.53033C6.23744 7.82322 5.76256 7.82322 5.46967 7.53033C5.17678 7.23744 5.17678 6.76256 5.46967 6.46967L9.46967 2.46967C9.61032 2.32902 9.80109 2.25 10 2.25ZM5.46967 12.4697C5.76256 12.1768 6.23744 12.1768 6.53033 12.4697L10 15.9393L13.4697 12.4697C13.7626 12.1768 14.2374 12.1768 14.5303 12.4697C14.8232 12.7626 14.8232 13.2374 14.5303 13.5303L10.5303 17.5303C10.2374 17.8232 9.76256 17.8232 9.46967 17.5303L5.46967 13.5303C5.17678 13.2374 5.17678 12.7626 5.46967 12.4697Z"
        fill={color ?? colors.gray["800"]}
      />
    </Svg>
  );
};

export default memo(ChevronUpDown) as typeof ChevronUpDown;

import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function PersonIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 64 64" fill="none">
      <Circle
        opacity="0.4"
        cx="32"
        cy="32"
        r="32"
        fill={props.clearBackground ? "transparent" : color || colors.blue.light}
      />
      <Path
        d="M41.6609 27.6715C41.6609 21.2595 37.8969 17.2715 31.8409 17.2715C25.7849 17.2715 22.0189 21.2595 22.0189 27.6715C22.0189 34.2275 26.0529 41.5335 31.8409 41.5335C37.6289 41.5335 41.6609 34.2275 41.6609 27.6715ZM31.8409 39.5335C27.7469 39.5335 24.0189 33.8775 24.0189 27.6715C24.0189 22.3375 26.8689 19.2715 31.8409 19.2715C36.8129 19.2715 39.6609 22.3315 39.6609 27.6715C39.6609 33.8775 35.9329 39.5335 31.8409 39.5335Z"
        fill={color || colors.blue.darker}
      />
      <Path
        d="M26.4209 40.9659H26.3929C22.6609 41.0659 15.4749 42.9039 15.0029 49.1439C14.9827 49.4091 15.0688 49.6715 15.242 49.8733C15.4153 50.0751 15.6617 50.1998 15.9269 50.2199C16.1921 50.2401 16.4545 50.154 16.6563 49.9808C16.858 49.8075 16.9827 49.5611 17.0029 49.2959C17.4529 43.2959 26.3609 42.9679 26.4509 42.9659C26.7161 42.9625 26.9691 42.8538 27.1542 42.6638C27.3393 42.4738 27.4413 42.2181 27.4379 41.9529C27.4344 41.6877 27.3258 41.4347 27.1358 41.2496C26.9458 41.0645 26.6901 40.9625 26.4249 40.9659H26.4209Z"
        fill={color || colors.blue.darker}
      />
      <Path
        d="M37.2889 40.9659H37.2629C36.9977 40.9622 36.7418 41.064 36.5517 41.2489C36.3615 41.4338 36.2526 41.6867 36.2489 41.9519C36.2452 42.2171 36.347 42.473 36.5319 42.6631C36.7168 42.8533 36.9697 42.9622 37.2349 42.9659C37.3249 42.9659 46.2349 43.2759 46.6809 49.2959C46.701 49.5611 46.8257 49.8075 47.0275 49.9808C47.2293 50.1541 47.4917 50.2401 47.7569 50.2199C48.0221 50.1998 48.2684 50.0751 48.4417 49.8733C48.615 49.6715 48.701 49.4091 48.6809 49.1439C48.2049 42.9039 41.0209 41.0659 37.2889 40.9659Z"
        fill={color || colors.blue.darker}
      />
    </Svg>
  );
}

import React, { useRef } from "react";
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  GestureResponderEvent,
  View,
  ScrollView,
} from "react-native";

import { NextLink } from "src/ui/components/NextLink/NextLink";
// TODO: Use Popover for menu items so the option can be visible when displayed out in the wild.
import Popover from "src/ui/components/Popover";

import { tailwind } from "../../styles";
import LoadingSpinner from "../LoadingSpinner";
// TODO: removed need for useDropdownCloseHandlers Popper handles escape
import {
  Option,
  /*, useDropdownCloseHandlers*/
} from "../Select/utils";
import Colors from "../colors.json";
import Icon from "../icons";

export type Action = {
  label: string;
  callback: () => void;
  // TODO: add disabled options
  disabled?: boolean;
  href?: string;
};

export type Props = {
  label: string;
  onPress?: (event: GestureResponderEvent) => void;
  testID?: string;
  size?: "sm" | "md" | "lg";
  noBorder?: boolean;
  disabled?: boolean;
  loading?: boolean;
  // TODO: make isOpen optional so users can use as a uncontrolled component
  isOpen: boolean;
  // TODO: make onClose optional so users can use as a uncontrolled component
  onClose: () => void;
  // TODO: make onOpen optional so users can use as a uncontrolled component
  onOpen: () => void;
  items: Array<Action>;
  // temporary - this will lock the popup to the right side of the button
  // (for use near the right side of the window)
  right?: boolean;
};

export default function DropdownButton(props: Props) {
  // Setup handlers for closing dropdown on offclick or escape
  const buttonRef = useRef<View>(null);
  // TODO: removed use of useDropdownCloseHandlers below as it is handled by the Popover
  // useDropdownCloseHandlers(selectRef, () => {
  //   if (isOpen) onClose();
  // });

  const { isOpen, onClose, onOpen, size = "md", noBorder = false } = props;
  const onClickButton = () => (isOpen ? onClose() : onOpen());

  return (
    <View style={tailwind("items-start z-dropdown")}>
      {/* buttonRef needs to be passed to this view, since this
      is the one that doesn't automatically stretch to fill row space */}
      <View style={tailwind("items-start")} ref={buttonRef}>
        <TouchableOpacity
          onPress={onClickButton}
          disabled={props.disabled}
          testID={props.testID}
          style={[
            tailwind(`rounded-full self-center justify-center`),
            { borderWidth: noBorder || props.disabled ? 0 : 1 },
            styles.container,
            styles[size],
          ]}
          accessibilityRole="button"
          accessibilityLabel={props.label}
          accessibilityHint="Opens a dropdown list of actions"
          accessibilityState={{ expanded: isOpen }}
        >
          <View style={tailwind("flex-row items-center justify-center")}>
            <>
              {/* TODO: should be updated to use paragraph so we have consistent text line heights and such */}
              <Text
                // @ts-expect-error TS2769: No overload matches this call.
                accessibilityRole="label"
                style={[styles.text, styles[`${size}Text`], props.disabled && styles.textDisabled]}
              >
                {props.label}
              </Text>

              {/* TODO: add animation for rotation to chevron icon */}
              {props.loading ? (
                <View style={tailwind("ml-3")}>
                  <LoadingSpinner size={16} />
                </View>
              ) : (
                <View
                  style={[
                    tailwind("ml-3"),
                    { transform: [{ rotate: isOpen ? "180deg" : "0deg" }] },
                  ]}
                >
                  <Icon
                    name="chevron-down"
                    size={10}
                    color={props.disabled ? Colors.gray[400] : Colors.gray[800]}
                  />
                </View>
              )}
            </>
          </View>
        </TouchableOpacity>
        {/* TODO: Changed to display in a Popover instead of an absolutely-positioned div) */}
        <Popover
          anchor={buttonRef}
          nativeID="dropdown-button-popover"
          isOpen={isOpen && props.items.length > 0}
          onClose={props.onClose}
          placement="bottom right"
        >
          {/* TODO: ensure that the default styling of popover meets expectations */}
          <ScrollView
            testID="action-button-dropdown"
            style={{ maxHeight: 550, width: 233 }}
            // TODO: - get rid of this right-0 style with future popover logic
            // TODO: Some of these styles are unnecessary when rendered inside a Popover
            // "bg-white rounded-lg shadow shadow-size-s flex-1 items-center flex-col p-0 py-2 top-full right-0 mt-2"
            contentContainerStyle={tailwind(`rounded-lg flex-1 items-center flex-col`)}
            accessibilityRole="menu"
          >
            {/* TODO: inline item so easier to view markup */}
            {props.items.map(({ callback, label, disabled, href }) =>
              href ? (
                <View key={label} style={tailwind("w-full")} accessibilityRole="menuitem">
                  <NextLink
                    href={href}
                    anchorProps={{ accessibilityLabel: label, target: "_blank" }}
                  >
                    <Option label={label} value={label} />
                  </NextLink>
                </View>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    onClose();
                    callback();
                  }}
                  disabled={disabled}
                  style={tailwind("w-full")}
                  key={label}
                  accessibilityRole="menuitem"
                >
                  <Option label={label} value={label} />
                </TouchableOpacity>
              )
            )}
          </ScrollView>
        </Popover>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.gray[100],
    borderColor: Colors.gray[800],
  },
  sm: {
    height: 32,
    paddingHorizontal: 12,
  },
  md: {
    height: 40,
    paddingHorizontal: 16,
  },
  lg: {
    height: 60,
    paddingHorizontal: 24,
  },
  text: {
    fontFamily: "Inter-semibold",
    color: Colors.gray[800],
  },
  textDisabled: {
    color: Colors.gray[400],
  },
  smText: {
    fontSize: 12,
    lineHeight: 20,
  },
  mdText: {
    fontSize: 14,
    lineHeight: 20,
  },
  lgText: {
    fontSize: 16,
    lineHeight: 24,
  },
});

export async function encodeUrlData(urlData: unknown): Promise<string> {
  if (!("CompressionStream" in window)) {
    return encodeURIComponent(JSON.stringify(urlData));
  }

  return compressAndEncodeUrlData(urlData);
}

async function compressAndEncodeUrlData(urlData: unknown): Promise<string> {
  const stream = new Blob([JSON.stringify(urlData)], {
    type: "application/json",
  }).stream();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const compressedReadableStream = stream.pipeThrough(new CompressionStream("gzip"));

  const compressedResponse = new Response(compressedReadableStream);

  const blob = await compressedResponse.blob();
  // Get the ArrayBuffer
  const buffer = await blob.arrayBuffer();

  // convert ArrayBuffer to base64 encoded string
  const compressedBase64 = btoa(String.fromCharCode(...new Uint8Array(buffer)));

  return compressedBase64;
}

/**
 *
 * this is not ideal, but right now tailwind does not support non cjs configs
 * meaning we can't use the export default syntax of ESM. We want this library to be
 * ESM only so that we can start to use modern tooling like vite and swc. Any changes
 * to this or the colors.ts file should be mirrored with each other
 *
 * See https://www.figma.com/file/gOpjecP0yWY5XUgZp8CNfD/%F0%9F%9A%A7-Color-Tokens?type=design&node-id=308-366&mode=design&t=EDfC7MKGUSWmabVb-0
 */

/**
 * Function which mixes "cvg-color-guide-(Ramp)" with "cvg-color-guide-(Core)" from the figma.
 *
 * The end goal here is to get an object like:
 *
 * {
 *   50: "#...",
 *   100: "#...",
 *   ...
 *   1000: "#...",
 *
 *   higher: { light: "#...", dark: "#..." },
 *   high: { light: "#...", dark: "#..." },
 *   ...
 * }
 */
function combine(ramp, cores) {
  const combined = {...ramp};
  Object.entries(cores).forEach(([coreKey, coreValues]) => {
    combined[coreKey] = {};
    Object.entries(coreValues).forEach(([themeKey, themeValue]) => {
      combined[coreKey][themeKey] = ramp[themeValue];
    });
  });
  return combined;
}

/**
 * Function which mixes "cvg-color-guide-(Ramp)" with "cvg-color-guide-(Semantic)" from the figma.
 * Note that some of the color ranges reach into grayscale and therefore are values
 * outside the standard ramp.
 *
 * The end goal here is to get an object like:
 * {
 *     subtle: {light: "#...", dark: "#..." },
 *     low: {light: "#...", dark: "#..." },
 *     ...
 * }
 */
function semanticBackgroundColors(colorCore, grayCore) {
  return {
    subtle: {light: colorCore["lowest"].light, dark: grayCore["highest"].light},
    low: {light: colorCore["lower"].light, dark: colorCore["higher"].light},
    ["low-accented"]: {light: colorCore["low"].light, dark: colorCore["high"].light},
    medium: {light: colorCore["low"].light, dark: colorCore["high"].light},
    high: {light: colorCore["high"].light, dark: colorCore["low"].light},
    ["high-accented"]: {light: colorCore["higher"].light, dark: colorCore["lower"].light},
  };
}

/**
 * Function which mixes "cvg-color-guide-(Ramp)" with "cvg-color-guide-(Semantic)" from the figma.
 *
 * The end goal here is to get an object like:
 * {
 *     subtle: {light: "#...", dark: "#..." },
 *     low: {light: "#...", dark: "#..." },
 *     ...
 * }
 */
function semanticForegroundColors(colorCore) {
  return {
    primary: {light: colorCore["higher"].light, dark: colorCore["lowest"].light},
    secondary: {light: colorCore["high"].light, dark: colorCore["low"].light},
    ["inverse-primary"]: {light: "#ffffff", dark: colorCore["higher"].light},
    ["inverse-secondary"]: {light: colorCore["lower"].light, dark: colorCore["high"].light},
  };
}

/**
 * Function which mixes "cvg-color-guide-(Ramp)" with "cvg-color-guide-(Semantic)" from the figma.
 *
 * The reason why we have different mapping for gray is that color ramp for gray is different than colored ones.
 */
function semanticForegroundGrayColor(grayCore) {
  return {
    primary: {light: grayCore.max.light, dark: grayCore["solid-min"].light},
    secondary: {light: grayCore.high.light, dark: grayCore.low.light},
    ["spot-readable"]: {light: grayCore.mid.light, dark: grayCore.mid.light},
    disabled: {light: grayCore.mid.light, dark: grayCore.mid.light},
    soft: {light: grayCore.low.light, dark: grayCore.high.light},
    softer: {light: grayCore.lower.light, dark: grayCore.higher.light},
    softest: {light: grayCore.lowest.light, dark: grayCore.highest.light},
    ["inverse-primary"]: {light: grayCore["solid-min"].light, dark: grayCore.max.light},
    ["inverse-secondary"]: {light: grayCore.low.light, dark: grayCore.high.light},
  };
}

/**
 * Function which mixes "cvg-color-guide-(Ramp)" with "cvg-color-guide-(Semantic)" from the figma.
 *
 * The reason why we have different mapping for gray is that color ramp for gray is different than colored ones.
 */
function semanticBackgroundGrayColor(grayCore) {
  return {
    base: {light: grayCore["solid-min"].light, dark: grayCore["solid-max"].light},
    min: {light: grayCore.min.light, dark: grayCore.max.light},
    subtle: {light: grayCore.lowest.light, dark: grayCore.highest.light},
    ["subtle-accented"]: {light: grayCore.lower.light, dark: grayCore.higher.light},
    low: {light: grayCore.lower.light, dark: grayCore.higher.light},
    ["low-accented"]: {light: grayCore.low.light, dark: grayCore.high.light},
    medium: {light: grayCore.low.light, dark: grayCore.high.light},
    ["inverse-base"]: {light: grayCore.max.light, dark: grayCore["solid-min"].light},
    ["inverse-low"]: {light: grayCore.high.light, dark: grayCore.low.light},
  };
}

/**
 * Function which mixes "cvg-color-guide-(Ramp)" with "cvg-color-guide-(Semantic)" from the figma.
 *
 * The reason why we have different mapping for foreground transparent is that the ramp for foreground transparent
 * is different from colored ones and gray ones.
 */
function semanticForegroundTransparent(transparents) {
  return {
    strong: {light: transparents.black["alpha-mid"], dark: transparents.white["alpha-mid"]},
    medium: {light: transparents.black["alpha-low"], dark: transparents.white["alpha-low"]},
    soft: {light: transparents.black["alpha-lower"], dark: transparents.white["alpha-lower"]},
    softer: {light: transparents.black["alpha-lowest"], dark: transparents.white["alpha-lowest"]},
    ["inverse-strong"]: {light: transparents.white["alpha-high"], dark: transparents.black["alpha-high"]},
    ["inverse-medium"]: {light: transparents.white["alpha-low"], dark: transparents.black["alpha-low"]},
    ["inverse-soft"]: {light: transparents.white["alpha-lower"], dark: transparents.black["alpha-lower"]},
    ["inverse-softer"]: {light: transparents.white["alpha-lowest"], dark: transparents.black["alpha-lowest"]},
  };
}

/**
 * Function which mixes "cvg-color-guide-(Ramp)" with "cvg-color-guide-(Semantic)" from the figma.
 *
 * The reason why we have different mapping for background transparent is that the ramp for background transparent
 * is different from colored ones and gray ones.
 */
function semanticBackgroundTransparent(transparents) {
  return {
    high: {light: transparents.black["alpha-high"], dark: transparents.white["alpha-high"]},
    medium: {light: transparents.black["alpha-low"], dark: transparents.white["alpha-low"]},
    ["low-accented"]: {light: transparents.black["alpha-low"], dark: transparents.white["alpha-low"]},
    low: {light: transparents.black["alpha-lower"], dark: transparents.white["alpha-lower"]},
    ["subtle-accented"]: {light: transparents.black["alpha-lower"], dark: transparents.white["alpha-lower"]},
    subtle: {light: transparents.black["alpha-lowest"], dark: transparents.white["alpha-lowest"]},
    min: {light: transparents.black["alpha-min"], dark: transparents.white["alpha-min"]},
    ["inverse-high"]: {light: transparents.white["alpha-high"], dark: transparents.black["alpha-high"]},
    ["inverse-medium"]: {light: transparents.white["alpha-low"], dark: transparents.black["alpha-low"]},
    ["inverse-low"]: {light: transparents.white["alpha-lower"], dark: transparents.black["alpha-lower"]},
    ["inverse-subtle"]: {light: transparents.white["alpha-lowest"], dark: transparents.black["alpha-lowest"]},
  };
}

// Most (Core) colors use these shades from the (Ramp).
// This just consolidates so we don't repeat them a bunch.
const standardCoreMap = {
  higher: {light: 800, dark: 100},
  high: {light: 600, dark: 200},
  mid: {light: 400, dark: 400},
  low: {light: 200, dark: 600},
  lower: {light: 100, dark: 800},
  lowest: {light: 50, dark: 900},
};

const grays = {
  10: "#ffffff",
  25: "#fafafa",
  50: "#f1f1f1",
  100: "#e1e1e1",
  200: "#d4d4d4",
  300: "#bcbcbc",
  400: "#a4a4a4",
  500: "#8b8b8b",
  600: "#676767",
  700: "#5f5f5f",
  800: "#424242",
  900: "#323232",
  1000: "#181818",
  1100: "#000000",
};
const grayCoreMap = {
  ["solid-max"]: {light: 1100, dark: 10},
  max: {light: 1000, dark: 25},
  highest: {light: 900, dark: 50},
  ...standardCoreMap,
  min: {light: 25, dark: 1000},
  ["solid-min"]: {light: 10, dark: 1100},
};

const transparents = {
  black: {
    ["alpha-high"]: "rgba(0, 0, 0, 0.60)",
    ["alpha-mid"]: "rgba(0, 0, 0, 0.36)",
    ["alpha-low"]: "rgba(0, 0, 0, 0.24)",
    ["alpha-lower"]: "rgba(0, 0, 0, 0.12)",
    ["alpha-lowest"]: "rgba(0, 0, 0, 0.06)",
    ["alpha-min"]: "rgba(0, 0, 0, 0.02)",
  },
  white: {
    ["alpha-high"]: "rgba(255, 255, 255, 0.68)",
    ["alpha-mid"]: "rgba(255, 255, 255, 0.48)",
    ["alpha-low"]: "rgba(255, 255, 255, 0.36)",
    ["alpha-lower"]: "rgba(255, 255, 255, 0.28)",
    ["alpha-lowest"]: "rgba(255, 255, 255, 0.20)",
    ["alpha-min"]: "rgba(255, 255, 255, 0.10)",
  }
}
const greens = {
  50: "#e3f5eb",
  100: "#cbedda",
  200: "#a9e2b3",
  300: "#76ce98",
  400: "#52b784",
  500: "#319d6d",
  600: "#247450",
  700: "#0e6c52",
  800: "#174b34",
  900: "#123928",
};

const blues = {
  50: "#e5f3f8",
  100: "#c9e6f0",
  200: "#b9dfea",
  300: "#8bc9de",
  400: "#6ab0ca",
  500: "#4d93af",
  600: "#376c89",
  700: "#306385",
  800: "#234658",
  900: "#1b3644",
};

const purples = {
  50: "#f3eff6",
  100: "#e9e3ee",
  200: "#ddcfeb",
  300: "#cfb7dd",
  400: "#b39cc5",
  500: "#a489bb",
  600: "#765c8b",
  700: "#6f5782",
  800: "#4c3c5a",
  900: "#3a2e45",
};

const yellows = {
  50: "#faf1ce",
  100: "#f3e197",
  200: "#eed366",
  300: "#d7ba5a",
  400: "#c1a14e",
  500: "#a9863c",
  600: "#7f6139",
  700: "#755a34",
  800: "#523f25",
  900: "#3f301c",
};

const reds = {
  50: "#fbeee9",
  100: "#f7e1d8",
  200: "#f0ceb7",
  300: "#e7b08c",
  400: "#e09361",
  500: "#bf6640",
  600: "#9a5435",
  700: "#904b2c",
  800: "#623725",
  900: "#4a2b1d",
};

const saturatedReds = {
  50: "#fcedeb",
  100: "#f8dad6",
  200: "#f5cbc5",
  300: "#ecada5",
  400: "#e18e85",
  500: "#d36d64",
  600: "#b33f3b",
  700: "#b4272c",
  800: "#712c28",
  900: "#552320",
};

const creams = {
  50: "#f4f0eb",
  100: "#e8e0d7",
  200: "#dbd3cb",
  300: "#c2bbb4",
  400: "#a9a39d",
  500: "#8f8a85",
  600: "#6a6662",
  700: "#625e5b",
  800: "#44423f",
  900: "#343230",
};

const green = combine(greens, standardCoreMap);
const gray = combine(grays, grayCoreMap);
const red = combine(reds, standardCoreMap);
const saturatedRed = combine(saturatedReds, standardCoreMap);
const yellow = combine(yellows, standardCoreMap);
const blue = combine(blues, standardCoreMap);
const purple = combine(purples, standardCoreMap);

module.exports = {
  white: "#ffffff",
  black: "#000000",
  focus: "#0000ff",
  gray,
  red,
  saturatedRed,
  yellow,
  green,
  blue,
  purple,
  error: {
    50: "#FEF9F8",
    100: "#FCEAE7",
    200: "#F5CBC5",
    300: "#ECADA5",
    400: "#E18E85",
    500: "#D36D64",
    600: "#C34A45",
    700: "#B4272C",
    800: "#980C1D",
    900: "#761318",
  },
  cream: combine(creams, standardCoreMap),
  transparent: {
    softest: "rgba(0, 0, 0, 0.02)",
    softer: "rgba(0, 0, 0, 0.06)",
    soft: "rgba(0, 0, 0, 0.12)",
    strong: "rgba(0, 0, 0, 0.6)",
  },
  // This is the semantic colors and should be the preferred method for choosing.
  bg: {
    transparent: semanticBackgroundTransparent(transparents),
    neutral: semanticBackgroundGrayColor(gray),
    positive: semanticBackgroundColors(green, gray),
    attention: semanticBackgroundColors(yellow, gray),
    alert: semanticBackgroundColors(saturatedRed, gray),
    information: semanticBackgroundColors(blue, gray),
    accent: semanticBackgroundColors(purple, gray),
    interactive: semanticBackgroundColors(blue, gray),
  },
  fg: {
    transparent: semanticForegroundTransparent(transparents),
    neutral: semanticForegroundGrayColor(gray),
    positive: semanticForegroundColors(green),
    attention: semanticForegroundColors(yellow),
    alert: semanticForegroundColors(saturatedRed),
    information: semanticForegroundColors(blue),
    accent: semanticForegroundColors(purple),
    interactive: semanticForegroundColors(blue),
  },

  // This is an older palette, and unlikely to change, so instead of making it
  // dynamic, we can just hardcode it.
  brand: {
    green: {
      lighter: "#B8F2C2",
      lighter_40: "rgba(184, 242, 194, 0.4)",
      lighter_50: "rgba(184, 242, 194, 0.5)",
      light: "#6BD9A1",
      DEFAULT: "#24A06D",
      dark: "#158562",
      darker: "#0B5C53",
    },
    mint: {
      lighter: "#E0FCE5",
      light: "#BCECCF",
      DEFAULT: "#A3E1C2",
      dark: "#77C1A3",
      darker: "#338272",
    },
    red: {
      lighter: "#FCD1A1",
      light: "#EC8E4E",
      DEFAULT: "#E15C18",
      dark: "#C14111",
      darker: "#821907",
    },
    orange: {
      light: "#E09361",
      DEFAULT: "#E15C18",
    },
    purple: {
      lighter: "#F7E4F5",
      light: "#D6C0D8",
      DEFAULT: "#BAA3BF",
      dark: "#9877A4",
      darker: "#56336E",
    },
    yellow: {
      lighter: "#F3ECBB",
      light: "#F3D95A",
      DEFAULT: "#EBC627",
      dark: "#CAA51C",
      darker: "#88690C",
    },
    blue: {
      lighter: "#9EEAEF",
      light: "#80D3DF",
      DEFAULT: "#58B3CA",
      dark: "#58B3CA",
      darker: "#1c4f75",
      darkerRoyal: "#3680EF",
    },
    gray: {
      800: "#19191B",
      "800_60": "rgba(25, 25, 27, 0.6)",
      "800_50": "rgba(25, 25, 27, 0.5)",
      600: "#404042",
      400: "#6E7071",
      200: "#A5A5A5",
      "100_50": "rgba(230, 232, 235, 0.5)",
      100: "#E6E8EB",
      50: "#F9F9F9",
    },
    "modal-opacity": "rgba(0, 0, 0, 0.4)",
  },
};

import { refreshCognitoToken } from "src/auth/utils/cognito";
import { refreshOktaToken } from "src/auth/utils/okta";
import { getTokenForCurrentUser, hasCognitoToken, hasOktaToken } from "src/auth/utils/userToken";

let lastRefreshId: number | undefined;
export const keepRefreshTokenAlive = (): void => {
  window.clearTimeout(lastRefreshId);
  const fortyFiveMinutesInMillis = 45 * 60 * 1000;
  lastRefreshId = window.setTimeout(refreshToken, fortyFiveMinutesInMillis);
};

/**
 * Get a new auth tokens for the current user and persist them.
 */
export async function refreshToken() {
  try {
    if (hasOktaToken()) {
      await refreshOktaToken();
    } else if (hasCognitoToken()) {
      await refreshCognitoToken();
    }
    return getTokenForCurrentUser();
  } finally {
    keepRefreshTokenAlive();
  }
}

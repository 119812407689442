import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {View, GestureResponderEvent, StyleSheet, TouchableOpacity, Platform} from "react-native";

import {noop} from "../utils/noop";
import useKeydown from "../utils/useKeydown";
import {Paragraph} from "../typography";

import {CheckboxProps} from "../../base/checkbox/types";
import colors from "../../base/colors";
import CheckmarkSmall from "../../base/icon/icons/checkmark-small";
import MinusSmall from "../../base/icon/icons/minus-small";
import {filterA11yRoleByPlatform} from "../../base/typography/a11y";

export type {CheckboxProps};

function Checkbox(props: CheckboxProps) {
  const {onChange = noop} = props;

  const checkboxRef = useRef<TouchableOpacity>(null);
  const labelRef = useRef<TouchableOpacity>(null);

  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = useCallback(() => setHovered(true), []);
  const handleMouseLeave = useCallback(() => setHovered(false), []);

  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      // Ignore "Enter" key because it shouldn't toggle the checkbox. Checkbox should be toggled
      // by the "Space" key.
      if (Platform.OS === "web" && event instanceof KeyboardEvent && event.code === "Enter") {
        return;
      }
      onChange(event);
    },
    [onChange],
  );

  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === "Space") {
        event.preventDefault();
        onChange(event);
      }
    },
    [onChange],
  );

  useKeydown(checkboxRef, handleKeydown, [handleKeydown]);

  const CheckedIcon =
    props.checked === "mixed" ? MinusSmall : props.checked === true ? CheckmarkSmall : undefined;

  const labelId = useMemo(() => `checkboxLabel-${Math.random().toString().replace(".", "")}`, []);

  // Make the label not focusable, similar to `<Pressable focusable={false} />`. Checkbox label is not focusable but,
  // on the web, should be interactive, i.e. toggles the checkbox when clicked/pressed, similar effect as having an
  // implicit label.
  //
  // <label>
  //   <input type="checkbox" /><span>Checkbox label</span>
  // </label>
  useEffect(() => {
    if (labelRef.current === null) return;
    labelRef.current.setNativeProps({tabIndex: -1});
  }, []);

  return (
    <View
      {...(Platform.OS === "web" && {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })}
      style={styles.root}
    >
      <TouchableOpacity
        activeOpacity={1}
        accessibilityRole="checkbox"
        accessibilityLabel={props.accessibilityLabel}
        accessibilityLabelledBy={props.accessibilityLabelledBy || labelId}
        // @ts-expect-error TS2322
        accessibilityControls={props.accessibilityControls}
        accessibilityState={{checked: props.checked, disabled: props.disabled}}
        accessibilityHint={props.accessibilityHint}
        accessibilityChecked={props.checked}
        hitSlop={props.hitSlop}
        nativeID={props.nativeID}
        testID={props.testID}
        onPress={handlePress}
        disabled={props.disabled}
        ref={checkboxRef}
        style={[
          styles.checkbox,
          hovered && styles.checkboxHovered,
          props.disabled && styles.checkboxDisabled,
          props.checked && styles.checkboxChecked,
          props.checked && hovered && styles.checkboxCheckedHovered,
          props.checked && props.disabled && styles.checkboxCheckedDisabled,
        ]}
      >
        {CheckedIcon && (
          <CheckedIcon size={14} color={props.disabled ? colors.white : colors.blue[800]} />
        )}
      </TouchableOpacity>

      {props.label ? (
        <TouchableOpacity
          activeOpacity={1}
          disabled={props.disabled}
          ref={labelRef}
          style={styles.label}
          onPress={handlePress}
        >
          <Paragraph
            level={2}
            accessibilityRole={filterA11yRoleByPlatform("label")}
            nativeID={labelId}
            color={props.disabled ? colors.gray[400] : undefined}
          >
            {props.label}
          </Paragraph>
        </TouchableOpacity>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    alignSelf: "flex-start",
  },
  checkbox: {
    width: 20,
    height: 20,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.gray[600],
    backgroundColor: colors.white,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxHovered: {
    borderColor: colors.blue[500],
    backgroundColor: colors.blue[50],
  },
  checkboxDisabled: {
    borderColor: colors.gray[400],
    backgroundColor: colors.gray[100],
  },
  checkboxChecked: {
    borderColor: colors.blue[800],
    backgroundColor: colors.blue[100],
  },
  checkboxCheckedHovered: {
    backgroundColor: colors.blue[200],
  },
  checkboxCheckedDisabled: {
    borderColor: colors.gray[400],
    backgroundColor: colors.gray[400],
  },
  label: {
    marginLeft: 8,
    flexShrink: 1,
  },
});

export default memo(Checkbox);

import * as React from "react";
import {StyleProp, View, ViewStyle} from "react-native";
import {Color as ColorType} from "react-native-svg";

import colors from "../colors";

import {IconSize} from "./types";

type IconComponent = React.JSXElementConstructor<{
  style: StyleProp<ViewStyle>;
  size: number;
  color: ColorType;
}>;

export type IconBaseProps = {
  color?: ColorType;
  accessibilityLabel?: string;
  IconComponent: IconComponent;
  iconSize: IconSize;
} & (
  | {
      ContainerComponent?: undefined;
      containerColor?: undefined;
      containerSize?: undefined;
    }
  | {
      ContainerComponent: IconComponent;
      containerColor: ColorType | undefined;
      containerSize: IconSize;
    }
);

export const sizes = {
  sm: 20,
  md: 24,
  lg: 32,
  xxl: 64,
};

export function IconBase(props: IconBaseProps) {
  const {
    color,
    accessibilityLabel,
    IconComponent,
    iconSize,
    ContainerComponent,
    containerColor,
    containerSize,
  } = props;

  const iconColor = color || colors.black;
  // default container color is white (won't be used without container passed)
  const bgColor = (ContainerComponent ? containerColor : null) || colors.white;

  // If, at runtime, there's an invalid size passed, don't render the component
  // this should be preventable by TS in most cases
  const iconSizeInPx = sizes[iconSize];
  if (!iconSizeInPx) return null;

  if (ContainerComponent) {
    const containerSizeInPx = sizes[containerSize];
    if (!containerSizeInPx) return null;
    const maxSize = Math.max(iconSizeInPx, containerSizeInPx);
    return (
      <View
        accessibilityLabel={accessibilityLabel}
        style={[styles.container, {width: maxSize, height: maxSize}]}
      >
        {/*
        when ContainerComponent or IconComponent is not wrapped with its own parent React.lazy in icon/index.web.tsx
        causes a crash: https://carbon.sentry.io/issues/4772631692. Wrapping at least one of them in a parent makes
        the problem go away magically. This is a workaround until we find the root cause.
        */}
        <>
          <ContainerComponent style={styles.icon} color={bgColor} size={containerSizeInPx} />
        </>
        <IconComponent
          style={[styles.icon, {minHeight: iconSizeInPx, minWidth: iconSizeInPx}]}
          size={iconSizeInPx}
          color={iconColor}
        />
      </View>
    );
  } else {
    return (
      <IconComponent
        size={iconSizeInPx}
        color={iconColor}
        style={{minHeight: iconSizeInPx, minWidth: iconSizeInPx}}
      />
    );
  }
}

export default IconBase;

// we can't use StyleSheet.create here because of interop issues with svgs for web
// https://github.com/godaddy/svgs/issues/39
const styles: Record<string, StyleProp<ViewStyle>> = {
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    position: "absolute",
  },
};

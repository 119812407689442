import Svg, { Path } from "react-native-svg";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

const ExclamationInTriangleFilled: React.FC<IconProps> = (props) => {
  const color = (props.disabled ? DISABLED_COLOR : props.color) ?? colors.yellow.DEFAULT;
  return (
    <Svg width={props.size} height={props.size} fill="none" viewBox="0 0 20 20">
      <Path
        fill={color}
        d="M17.72 17.5H2.28a.83.83 0 0 1-.72-1.25L9.28 2.9a.83.83 0 0 1 1.44 0l7.72 13.34a.83.83 0 0 1-.72 1.25Zm-8.55-4.17V15H10.83v-1.67H9.17Zm0-5.83v4.16h1.66V7.5H9.17Z"
      />
    </Svg>
  );
};

export default ExclamationInTriangleFilled;

import React, {memo} from "react";
import {Text} from "react-native";
import colors from "../colors";
import {LegacyStyleProps, TypographyBaseProps, TypographyWeight} from "./types";

// this is the type for the publicly exported component. Since it'll be
// shared among pro/consumer, it can live in base.
export type ParagraphProps = TypographyBaseProps &
  LegacyStyleProps &
  TypographyWeight & {
    // level 1 - largest, 3 - smallest
    level?: 1 | 2 | 3;
    numberOfLines?: number;
  };

type BaseParagraphProps = TypographyBaseProps;

export default memo(function Paragraph({
  style,
  color = colors.gray[1000],
  ...props
}: BaseParagraphProps) {
  return <Text {...props} style={[style, {color}]} />;
});

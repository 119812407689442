import * as React from "react";
import {View} from "react-native";
import {IconWrapperProps, IconName, ContainerName} from "./types";
import IconBase, {sizes} from "./IconBase";

export type {IconName, ContainerName, IconWrapperProps};

/**
 * Since we lazy load icons in on web, if we are unable to load an import
 * we should return a noop component so that we do not crash the page. In the future
 * we should determine a fallback icon shape with design and load that instead to not shift
 * layout, but for a quick fix for a potentially bad bug, this is okay
 *
 * @returns React.ReactNode
 */
const FallBackImport = {default: () => null};
export function Icon(props: IconWrapperProps) {
  const {
    name,
    containerName,
    color,
    iconSize,
    size,
    accessibilityLabel,
    containerColor,
    containerSize,
  } = props;
  const IconComponent = React.lazy(() =>
    import(`./icons/${name.toLowerCase()}.tsx`).catch(() => FallBackImport),
  );

  const ContainerComponent =
    containerName &&
    React.lazy(() =>
      import(`./icons/${containerName.toLowerCase()}.tsx`).catch(() => FallBackImport),
    );

  const resolvedContainerSize = ContainerComponent ? containerSize ?? size : undefined;

  const resolvedIconSize = iconSize ?? size;
  const maxSize = resolvedContainerSize
    ? Math.max(sizes[resolvedIconSize], sizes[resolvedContainerSize])
    : sizes[resolvedIconSize];
  const FallbackElement = <View style={{width: maxSize, height: maxSize}} />;

  return (
    <React.Suspense fallback={FallbackElement}>
      {ContainerComponent && resolvedContainerSize ? (
        <IconBase
          color={color}
          containerSize={resolvedContainerSize}
          iconSize={resolvedIconSize}
          accessibilityLabel={accessibilityLabel}
          IconComponent={IconComponent}
          containerColor={containerColor}
          ContainerComponent={ContainerComponent}
        />
      ) : (
        <IconBase
          color={color}
          iconSize={resolvedIconSize}
          accessibilityLabel={accessibilityLabel}
          IconComponent={IconComponent}
        />
      )}
    </React.Suspense>
  );
}

export default React.memo(Icon);

import createVisitorPersistenceApi from "./persistence";
import createVisitorStateAPI, {VisitorStateAPI} from "./state";
import {VisitorInput} from "./types";

export type VisitorAPI = VisitorStateAPI & {
  /**
   * Load visit and visitor IDs from persistence into in-memory state.
   */
  restoreVisit(): Promise<void>;
};

export type CreateVisitorAPIOptions = {
  /**
   * Called when a visit is set, after saving to persistence.
   */
  onSetVisit?: (input: VisitorInput) => Promise<unknown>;
  /**
   * Whether this is running in a web browser. Defaults to false.
   */
  isWeb?: boolean;
};

/**
 * Create a singleton for managing the visitor and visit IDs,
 * including in-memory state and long-term persistence.
 */
export default function createVisitorAPI({onSetVisit, isWeb}: CreateVisitorAPIOptions): VisitorAPI {
  const persistence = createVisitorPersistenceApi({isWeb});
  const state = createVisitorStateAPI();

  async function setVisitInMemory(input: VisitorInput) {
    state.setVisit(input);
    await onSetVisit?.(input);
  }

  return {
    async setVisit(input) {
      persistence.saveVisit(input);
      await setVisitInMemory(input);
    },
    async restoreVisit() {
      const visitorInput = await persistence.loadVisit();
      if (visitorInput) {
        await setVisitInMemory(visitorInput);
      }
    },
    get visitorId() {
      return state.visitorId;
    },
    get visitId() {
      return state.visitId;
    },
  };
}

import React from "react";
import {StyleSheet} from "react-native";
import {Heading} from "../../base/typography";
import type {HeadingProps} from "../../base/typography/Heading";
import {interFontFamilies} from "../../base/typography/styles";

export function ProHeading({weight = "regular", legacyStyle, ...props}: HeadingProps) {
  return (
    <Heading
      style={[headingStyles[props.level], interFontFamilies[weight], legacyStyle]}
      {...props}
    />
  );
}

export const headingStyles = StyleSheet.create({
  0: {
    fontSize: 64,
    lineHeight: 78,
  },
  1: {
    fontSize: 32,
    lineHeight: 48,
  },
  2: {
    fontSize: 28,
    lineHeight: 42,
  },
  3: {
    fontSize: 24,
    lineHeight: 36,
  },
  4: {
    fontSize: 20,
    lineHeight: 32,
  },
});

export default ProHeading;

import AsyncStorage from "@react-native-async-storage/async-storage";
import {omitBy} from "lodash";

import {UUID} from "../../types/primitives";
import {loggedInPracticeIdsStorageKey, activeLoggedInPracticeIdStorageKey} from "../../constants/practices";

// TODO: remove this in favor of async storage
// difficult for now because of api.getDefaultHeaders() in hib
let activePracticeId: UUID | null = null;

export const carbonActivePracticeIdKey = "CarbonActivePracticeId";

export const setActivePracticeId = (practiceId: UUID) => {
  activePracticeId = practiceId;
  return AsyncStorage.setItem(activeLoggedInPracticeIdStorageKey, practiceId);
};

export const getActicePracticeId = () => {
  return activePracticeId;
};

export const getActiveLoggedInPracticeId = async () => {
  return AsyncStorage.getItem(activeLoggedInPracticeIdStorageKey);
}

export const clearLoggedInPracticeIds = (userId: UUID) =>
  getLoggedInPracticeIds().then(
    loggedInPracticeIds => {
      AsyncStorage.setItem(
        loggedInPracticeIdsStorageKey,
        JSON.stringify(omitBy(loggedInPracticeIds, value => value === userId)),
      );
      activePracticeId = null;
    }
  ).then(() => 
    AsyncStorage.removeItem(activeLoggedInPracticeIdStorageKey)
  );

export const resetLoggedInPracticeIds = (loggedInPracticeIds: {[practiceId: UUID]: UUID}) =>
  AsyncStorage.setItem(loggedInPracticeIdsStorageKey, JSON.stringify(loggedInPracticeIds));

export const getLoggedInPracticeIds = () =>
  AsyncStorage.getItem(loggedInPracticeIdsStorageKey)
    .then(loggedInPracticeIds => (loggedInPracticeIds ? JSON.parse(loggedInPracticeIds) : {}))
    .catch(() => ({})); // TODO: log the error;

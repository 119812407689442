import AsyncStorage from "@react-native-async-storage/async-storage";
import {visitIdStorageKey, visitorIdStorageKey} from "./constants";
import {VisitorInput} from "./types";

type VisitorPersistenceAPI = {
  /**
   * Set the visit & visitor params.
   *
   * It may not be obvious why we're storing visit/visitor IDs in localStorage _and_ cookies.
   * The reason is that carbon-website-next (carbonhealth.com) and the Patient app
   * (carbonhealth.com/patients) are served on the same domain, and we want to track users
   * smoothly across them.
   *
   * The issue is that carbon-website-next is an actual Server, and does some server-side rendering.
   * If a user enters "carbonhealth.com" into their address bar, it will need the visit & visitorId
   * _before the javascript loads_, so there's no opportunity to read from localStorage.
   * The patient app doesn't have this problem, because it's all client-side rendered.
   *
   * So why not rely on the Cookie, and ignore localStorage? Because the Patient app is coded in
   * React Native, and native apps prefer AsyncStorage, which writes to localStorage in
   * react-native-web.
   *
   * The big picture works like this:
   *   1. If a patient visits "carbonhealth.com", carbon-website-next prioritizes the _cookie_ value.
   *      As soon as the page loads, it overwrites the value in localStorage.
   *   2. If a patient visits "carbonhealth.com/patients", the patient app prioritizes the
   *      _localStorage_ value, but then overwrites the value in the Cookie.
   *
   * In theory these two should be the same all the time, with one exception: if a user deletes
   * their cookies, and then visits carbonhealth.com, the two will be out of sync for the first
   * SSR. Once the page loads, the client-side code will make them consistent again.
   */
  saveVisit(input: VisitorInput): Promise<unknown>;
  loadVisit(): Promise<VisitorInput | undefined>;
};

type CreateVisitorPersistenceAPIOptions = {
  isWeb?: boolean;
};

export default function createVisitorPersistenceApi({
  isWeb = false,
}: CreateVisitorPersistenceAPIOptions = {}): VisitorPersistenceAPI {
  return {
    async loadVisit() {
      const [[_1, visitorId] = [], [_2, visitId] = []] = await AsyncStorage.multiGet([
        visitorIdStorageKey,
        visitIdStorageKey,
      ]);
      if (visitorId && visitId) {
        return {visitorId, visitId};
      }
    },
    saveVisit({visitId, visitorId}) {
      return Promise.all([
        AsyncStorage.setItem(visitorIdStorageKey, visitorId).then(() => {
          if (isWeb) {
            document.cookie = `visitorId=${visitorId}; path=/`;
          }
        }),
        AsyncStorage.setItem(visitIdStorageKey, visitId).then(() => {
          if (isWeb) {
            document.cookie = `visitId=${visitId}; path=/`;
          }
        }),
      ]);
    },
  };
}

import * as React from "react";
import Svg, {Path} from "react-native-svg";
import type {SingleIconProps} from "../types";

const SvgMinusSmall = (props: SingleIconProps) => (
  <Svg
    width={props.size}
    height={props.size}
    viewBox="0 0 20 20"
    fill="none"
    title={props.accessibilityLabel}
    style={props.style}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 10C2.25 9.58579 2.58579 9.25 3 9.25H17C17.4142 9.25 17.75 9.58579 17.75 10C17.75 10.4142 17.4142 10.75 17 10.75H3C2.58579 10.75 2.25 10.4142 2.25 10Z"
      fill={props.color}
    />
  </Svg>
);

export default SvgMinusSmall;

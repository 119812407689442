import { View } from "react-native";

import { useAuthContext } from "src/auth/hooks/useAuthContext";
import { Action, canTakeAction } from "src/auth/utils/canTakeAction";
import GlobalSearch from "src/common/components/GlobalSearch";
import PracticeSelector from "src/common/components/PracticeSelector";
import { tailwind } from "src/foundations/styles";
import { Icon, CarbonLogoText } from "src/foundations/ui";
import { NextLink } from "src/ui/components/NextLink/NextLink";

import NavigationMenu from "./NavigationMenu";

export const TopNav: React.FC = () => {
  const { status } = useAuthContext();
  return (
    <View
      // @ts-expect-error TS2769: No overload matches this call.
      accessibilityRole="navigation"
      style={tailwind(
        "flex flex-none w-full flex-row items-center justify-start px-8 py-7 shadow-size-s z-header"
      )}
    >
      <NextLink href="/" anchorProps={{ accessibilityLabel: "Dashboard Home" }}>
        <View style={tailwind("flex flex-row items-center")}>
          <Icon name="carbon" size={24} />
          <View style={tailwind("ml-3")}>
            <CarbonLogoText height={16} />
          </View>
        </View>
      </NextLink>
      {status === "authenticated" && (
        <>
          <View style={tailwind("flex-1 ml-4 items-start")}>
            <PracticeSelector />
          </View>
          <View style={tailwind("flex-1 items-end")}>
            {canTakeAction(Action.SEARCH) ? <GlobalSearch /> : null}
          </View>
          <View style={tailwind("ml-4 flex-none flex-row-reverse")}>
            <NavigationMenu />
          </View>
        </>
      )}
    </View>
  );
};

export default TopNav;

import { colors, Icon } from "@ch/ui/pro";
import Checkbox from "@ch/ui/pro/checkbox";
import { LegacyRef, memo, Ref, useCallback, useEffect, useMemo, useRef } from "react";
import { StyleProp, View, ViewStyle, TouchableOpacity, Text, Pressable } from "react-native";

import { TriStateSortDirection } from "src/common/types/sorting";
import { useHover } from "src/foundations/hooks/useHover";
import { tailwind } from "src/foundations/styles";
import { Paragraph } from "src/foundations/ui";

import { TableColProp, TableRowProp } from ".";
import TableCell from "./TableCell";

type TableRowProps<T extends TableRowProp> = {
  row?: T;
  cols: TableColProp[];
  style?: StyleProp<ViewStyle>;
  isHeader?: boolean;
  rowId?: number; // Unique id for row to be passed back with callbacks
  // Selectable props
  selectable?: boolean; // Whether or not this row can be selected using a checkbox
  selected?: boolean; // Whether or not this row should show selected styles
  selectDisabled?: boolean;
  onSelectChange?: (row?: T) => void;
  // Hover props (for selectable rows)
  hoveredOverride?: boolean; // Force show hovered styles
  onHoverChange?: (hovered: boolean, rowId: number | undefined) => void;
  sortedColumns?: Array<{ field: string; direction: TriStateSortDirection }>;
  onHeaderColumnClick?: (field: string, direction?: TriStateSortDirection) => void;
  // Checkbox props
  checkboxAccessibilityLabel?: string;
  testID?: string;
  onRowPress?: (row: T) => void;
  minimal?: boolean;
};

function TableRow<T extends TableRowProp>({
  row,
  style,
  isHeader,
  cols,
  rowId,
  selectable,
  selected,
  selectDisabled,
  onSelectChange,
  hoveredOverride,
  onHoverChange,
  checkboxAccessibilityLabel,
  sortedColumns,
  onHeaderColumnClick,
  testID,
  onRowPress,
  minimal,
}: TableRowProps<T>) {
  const [ref, hovered] = useHover();
  const hasMounted = useRef<boolean>(false);
  const isStriped = minimal && !isHeader && rowId !== undefined && rowId % 2 === 0;

  useEffect(() => {
    if (!hasMounted.current) {
      // Do not trigger hover changes for initial state
      hasMounted.current = true;
      return;
    }
    if (onHoverChange && typeof hovered === "boolean") {
      onHoverChange(hovered, rowId);
    }
  }, [hovered, onHoverChange, rowId]);

  const onCheckboxChange = () => {
    if (onSelectChange) {
      onSelectChange(row);
    }
  };

  const getStyle = () => {
    if (isHeader) {
      // Headers should have no special styles
      return style;
    }
    if (selected) {
      return tailwind("bg-green-lighter bg-opacity-40");
    }
    if (hoveredOverride || (selectable && hovered)) {
      return minimal ? { backgroundColor: colors.gray[100] } : tailwind("bg-gray-50");
    }

    if (isStriped) {
      return [style, { backgroundColor: colors.gray[50] }];
    }

    return style;
  };

  const children = useMemo(
    () =>
      cols.map((col, colIndex) => {
        const sortDirection = sortedColumns?.find((scol) => scol.field === col.field)?.direction;
        const value = row?.[col.field];
        return isHeader ? (
          <MemoizedTableHeader
            key={colIndex}
            col={col}
            colId={colIndex}
            selectable={selectable}
            sortDirection={sortDirection}
            onHeaderColumnClick={onHeaderColumnClick}
            minimal={minimal}
          />
        ) : (
          <TableCell
            key={`${rowId} ${colIndex}`}
            style={[
              getCellStyles(colIndex, selectable, col.width),
              tailwind("h-full justify-center"),
              {
                backgroundColor: sortDirection
                  ? colors.blue[50] // sorted column background
                  : selected
                  ? "" // no background use row's background if the row is selected
                  : minimal && (hoveredOverride || (selectable && hovered))
                  ? colors.gray[100] // hovered background, only for minimal table
                  : isStriped
                  ? colors.gray[50] // striped row background
                  : "",
              },
            ]}
            testID={`${col.field}Row${rowId}`}
          >
            <View style={{ width: col.width ?? "auto", minWidth: col.width ?? CELL_MIN_WIDTH }}>
              {value && typeof value === "object" && "Component" in value ? (
                typeof value.Component === "function" ? (
                  value.Component({ rowIsHovered: hoveredOverride })
                ) : (
                  value.Component ?? (
                    <Paragraph level={2} style={col.textStyle}>
                      -
                    </Paragraph>
                  )
                )
              ) : (
                <Paragraph
                  level={2}
                  style={col.textStyle ? col.textStyle : {}}
                  // TODO: number of lines default to 1 instead of 2
                  numberOfLines={1}
                >
                  {value ?? "-"}
                </Paragraph>
              )}
            </View>
          </TableCell>
        );
      }),
    [
      cols,
      hoveredOverride,
      selected,
      selectable,
      row,
      rowId,
      isHeader,
      sortedColumns,
      onHeaderColumnClick,
    ]
  );

  const handleRowPress = useCallback(
    () => onRowPress && row !== undefined && onRowPress(row),
    [row, onRowPress]
  );

  return onRowPress ? (
    <TouchableOpacity
      style={[style, getStyle()]}
      // @ts-expect-error TS2769: No overload matches this call.
      accessibilityRole="row"
      ref={ref as LegacyRef<TouchableOpacity>}
      testID={testID}
      onPress={handleRowPress}
    >
      {selectable && (
        <TableCell style={tailwind("ml-6 mr-3")}>
          <Checkbox
            checked={selected}
            onChange={onCheckboxChange}
            accessibilityLabel={checkboxAccessibilityLabel || "Select row"}
            disabled={selectDisabled}
          />
        </TableCell>
      )}
      {children}
    </TouchableOpacity>
  ) : (
    <View
      // TODO: added position relative
      style={[style, tailwind("relative"), getStyle()]}
      // @ts-expect-error TS2769: No overload matches this call.
      accessibilityRole="row"
      ref={ref as LegacyRef<View>}
      testID={testID}
    >
      {selectable && (
        <TableCell style={tailwind("ml-6 mr-3")}>
          <Checkbox
            checked={selected}
            onChange={onCheckboxChange}
            accessibilityLabel={checkboxAccessibilityLabel || "Select row"}
            disabled={selectDisabled}
          />
        </TableCell>
      )}
      {children}
    </View>
  );
}

export default memo(TableRow) as typeof TableRow;

// TODO: change default width to 152
const CELL_MIN_WIDTH = 152;
const getCellStyles = (
  colIndex: number,
  hasSelectableRows: boolean | undefined,
  width: number | undefined
) => {
  // TODO: honor the fixed with for each column
  const flex = width === undefined ? "flex-1" : "";
  // TODO: honor the fixed width for each column
  // const widthStyle = { width: width && width > CELL_MIN_WIDTH ? width : CELL_MIN_WIDTH };
  if (!hasSelectableRows && colIndex === 0) {
    // If rows are not selectable, and this is the first column, use a larger
    // left margin since there will not be a preceding checkbox cell
    // TODO: honor the fixed with for each column
    return [tailwind(`${flex} ml-6 mr-3`)];
  }
  // TODO: honor the fixed with for each column
  return [tailwind(`${flex} px-3`)];
};

type TableHeaderProps<T extends TableColProp> = {
  col: T;
  colId: number;
  selectable?: boolean;
  sortDirection: T extends { sortable: true } ? TriStateSortDirection : undefined;
  minimal?: boolean;
  onHeaderColumnClick: T extends { sortable: true }
    ? (field: string, direction?: TriStateSortDirection) => void
    : undefined;
};

function TableHeader({
  col,
  colId,
  selectable,
  sortDirection,
  onHeaderColumnClick,
  minimal,
}: TableHeaderProps<any>) {
  const [ref, hovered] = useHover();

  const handleCellPress = useCallback(() => {
    if (col.sortable) {
      onHeaderColumnClick?.(col.field, sortDirection);
    }
  }, [col.sortable, sortDirection, onHeaderColumnClick]);

  // no header background color for minimal table
  let headerColumnColor = minimal ? undefined : colors.gray[50];
  const sorted = col.sortable && sortDirection;
  if (sorted && hovered) {
    headerColumnColor = colors.gray[100];
  } else if (sorted) {
    headerColumnColor = colors.blue[100];
  }

  return (
    <TableCell
      style={[
        getCellStyles(colId, selectable, col.width),
        tailwind("h-full justify-center"),
        { backgroundColor: headerColumnColor },
      ]}
      accessibilityRole="columnheader"
    >
      <Pressable
        ref={ref as Ref<View>}
        style={[
          tailwind("h-full flex flex-row items-center"),
          { width: col.width ?? "auto", minWidth: col.width ?? CELL_MIN_WIDTH },
        ]}
        onPress={handleCellPress}
        // needed as accessing first child in tests is discouraged
        testID={`pressable-${col.field}-column`}
        disabled={!col.sortable}
      >
        <Text style={tailwind("font-bold font-inter-semibold text-xs text-gray-600")}>
          {col.name}
        </Text>
        {col.decorator}
        {col.sortable && hovered && !sortDirection && (
          <View style={tailwind("ml-2")}>
            <Icon name="arrow-up-small" size="sm" color={colors.gray[400]} />
          </View>
        )}
        {col.sortable && sortDirection && (
          <View style={tailwind("ml-2")}>
            {sortDirection === "ASC" ? (
              <Icon name="arrow-up-small" size="sm" />
            ) : (
              <Icon name="arrow-down-small" size="sm" />
            )}
          </View>
        )}
      </Pressable>
    </TableCell>
  );
}

const MemoizedTableHeader = memo(TableHeader);

import * as React from "react";
import Svg, {Path} from "react-native-svg";
import type {SingleIconProps} from "../types";

const SvgCheckmarkSmall = (props: SingleIconProps) => (
  <Svg
    width={props.size}
    height={props.size}
    viewBox="0 0 20 20"
    fill="none"
    title={props.accessibilityLabel}
    style={props.style}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5303 4.46967C17.8232 4.76256 17.8232 5.23744 17.5303 5.53033L7.53033 15.5303C7.23744 15.8232 6.76256 15.8232 6.46967 15.5303L2.46967 11.5303C2.17678 11.2374 2.17678 10.7626 2.46967 10.4697C2.76256 10.1768 3.23744 10.1768 3.53033 10.4697L7 13.9393L16.4697 4.46967C16.7626 4.17678 17.2374 4.17678 17.5303 4.46967Z"
      fill={props.color}
    />
  </Svg>
);

export default SvgCheckmarkSmall;

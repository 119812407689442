import React, {useEffect} from "react";
import {Platform} from "react-native";

/**
 * Attach a `keydown` event listener to a DOM element.
 *
 * May not work properly in `hib` because some component's ref would be `ReactClassComponent`
 * instead of the actual DOM node (e.g. `<div>`). This is caused by an older version of
 * `react-native-web` using something like `createReactClass()` to create components and not
 * forwarding the ref properly.
 */
export function useKeyDown(
  ref: React.MutableRefObject<unknown>,
  handler: (event: KeyboardEvent) => void,
  deps?: unknown[],
): void {
  useEffect(() => {
    if (Platform.OS !== "web") {
      // warn in develompent that this doesn't work on native
      if (process.env.NODE_ENV !== "production") {
        console.warn("Keyboard interaction is not supported on non-web platform");
      }
      return;
    }

    // See note above about older react-native-web compatibility.
    if (!(ref.current instanceof HTMLElement)) return;

    const domElement = ref.current;
    domElement.addEventListener("keydown", handler);

    return () => {
      domElement.removeEventListener("keydown", handler);
    };
    // Just run effect once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? []);

  return;
}

export default useKeyDown;

import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from "@apollo/client";
import "@apollo/client/cache";
import { TypePolicies } from "@apollo/client/cache";
import { onError } from "@apollo/client/link/error";
import { RestLink } from "apollo-link-rest";
import isNil from "lodash/isNil";
import { default as deepMerge } from "lodash/merge";

import authLink from "src/auth/utils/authLink";
import { USER_TIMEZONE } from "src/common/constants";
import { demoModeTypePolicies } from "src/common/utils/demoMode";
import { logError } from "src/common/utils/reporting";
import fragmentMatcher from "src/graphql/types/__generated__/possibleTypes";

import { graphQLFetcher, monolithFetcher } from "./fetching";

const enum GraphQLErrorType {
  INTERNAL = "INTERNAL",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  BAD_REQUEST = "BAD_REQUEST",
}

const httpLink = new HttpLink({
  fetch: graphQLFetcher,
});

const addTimezoneVariableLink = new ApolloLink((operation, forward) => {
  if (operation.variables.timezone === undefined) {
    operation.variables.timezone = USER_TIMEZONE;
  }
  return forward(operation);
});

const rulesEngineMutationErrorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const data = response.data ? response.data[Object.keys(response.data)[0]] : undefined;

    // for some responses we only return success and message. These come from rules engine and have codes
    const isUnsuccessfulRulesEngineMutationResponse =
      data &&
      data.success === false &&
      ("codes" in data || // if codes are present, check if any of them are rules engine errors
        operation.operationName.toLowerCase().includes("rule")); // if codes are not present, check if operation name includes "Rule"

    if (isUnsuccessfulRulesEngineMutationResponse) {
      const { operationName, variables } = operation;
      const error = new Error(data.message);
      error.name = "RulesEngineMutationError";
      data?.codes?.forEach((code: string) => {
        logError(error, {
          customMessage: `[${operationName}]: ${code}: ${
            data.message || "Internal Server Error occurred"
          }`,
          extraData: { operationName, variables: JSON.stringify(variables, null, 2) },
        });
      });
    }
    return response;
  });
});

const restResponseTransformer = async (response: Response) =>
  response?.blob()?.then((blob: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  });

const restLink = new RestLink({
  // Base URI is defined in monolithFetcher, endpoint path will be defined by the consumer.
  uri: "",
  customFetch: monolithFetcher,
  endpoints: {
    apptPdf: {
      uri: "/billinghub/appointments",
      responseTransformer: async (response) => response?.text(),
    },
    privateImage: {
      uri: "/billinghub/privateImages",
      responseTransformer: restResponseTransformer,
    },
    paperInvoicePdf: {
      uri: "/paperInvoices/getPdf",
      responseTransformer: restResponseTransformer,
    },
    lockboxDocumentPdf: {
      uri: "/billinghub/lockboxDocument",
      responseTransformer: restResponseTransformer,
    },
  },
});

const errorLink = onError(({ graphQLErrors, operation }) => {
  const { operationName, variables } = operation;

  graphQLErrors?.forEach(({ extensions, message, locations, path }) => {
    const error = new Error(message);
    error.name = "GraphQLError";
    if (
      extensions.errorType === GraphQLErrorType.INTERNAL ||
      extensions.errorType === GraphQLErrorType.INTERNAL_SERVER_ERROR ||
      extensions.errorType === GraphQLErrorType.BAD_REQUEST
    ) {
      logError(error, {
        extraData: {
          extensions,
          locations,
          path,
          operationName,
          variables: JSON.stringify(variables, null, 2),
        },
        customMessage: `[${operationName}]: ${extensions.errorType} Error occurred: Message: ${message}, Location: ${locations}, Path: ${path}`,
      });
    }
    console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
  });
});

const typePolicy: TypePolicies = {
  Bill: {
    fields: {
      totals: {
        merge(existing, incoming, { mergeObjects }) {
          return existing?.__typename === incoming?.__typename
            ? mergeObjects(existing, incoming)
            : incoming;
        },
      },
      responsibleParty: {
        merge(existing, incoming, { mergeObjects }) {
          return existing?.__typename === incoming?.__typename
            ? mergeObjects(existing, incoming)
            : incoming;
        },
      },
    },
  },

  Patient: {
    fields: {
      insuranceCardScans: {
        keyArgs: false,
        merge(existing, incoming, { mergeObjects }) {
          if (isNil(incoming) || isNil(existing)) return incoming;
          const { nodes, ...merged } = mergeObjects(existing, incoming);
          return {
            ...merged,
            nodes: isNil(incoming?.nodes)
              ? incoming?.nodes
              : [...(existing?.nodes ?? []), ...(incoming?.nodes ?? [])],
          };
        },
      },
      identificationCardScans: {
        keyArgs: false,
        merge(existing, incoming, { mergeObjects }) {
          if (isNil(incoming) || isNil(existing)) return incoming;

          const { nodes, ...merged } = mergeObjects(existing, incoming);
          return {
            ...merged,
            nodes: isNil(incoming?.nodes)
              ? incoming?.nodes
              : [...(existing?.nodes ?? []), ...(incoming?.nodes ?? [])],
          };
        },
      },

      events: {
        keyArgs: false,
        merge(existing, incoming, { mergeObjects }) {
          if (isNil(incoming) || isNil(existing)) return incoming;

          const { nodes, ...merged } = mergeObjects(existing, incoming);
          return {
            ...merged,
            nodes: isNil(incoming?.nodes)
              ? incoming?.nodes
              : [...(existing?.nodes ?? []), ...(incoming?.nodes ?? [])],
          };
        },
      },
    },
  },

  PaymentMethod: {
    fields: {
      creditCard: {
        merge(existing, incoming, { mergeObjects }) {
          return existing?.__typename === incoming?.__typename &&
            existing?.last4Digits === incoming?.last4Digits &&
            existing?.issuer === incoming?.issuer
            ? mergeObjects(existing, incoming)
            : incoming;
        },
      },
    },
  },

  OrganizationInvoice: {
    fields: {
      supersededInvoices: {
        keyArgs: false,
        merge(existing, incoming, { mergeObjects }) {
          if (isNil(incoming) || isNil(existing)) return incoming;

          const { nodes, ...merged } = mergeObjects(existing, incoming);
          return {
            ...merged,
            nodes: isNil(incoming?.nodes)
              ? incoming?.nodes
              : [...(existing?.nodes ?? []), ...(incoming?.nodes ?? [])],
          };
        },
      },
    },
  },
};

export const getNewCacheInstance = () =>
  new InMemoryCache({
    typePolicies: deepMerge(demoModeTypePolicies, typePolicy),
    possibleTypes: fragmentMatcher.possibleTypes,
  });

export const client = new ApolloClient({
  link: from([
    errorLink,
    restLink,
    rulesEngineMutationErrorLink,
    authLink,
    addTimezoneVariableLink,
    httpLink,
  ]),
  cache: getNewCacheInstance(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
  },
});

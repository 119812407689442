import jwtDecode, { JwtPayload } from "jwt-decode";
import isNil from "lodash/isNil";

import {
  AUTH_TOKEN_STORAGE_KEY,
  CURRENT_USER_ID_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from "src/auth/constants";
import { AccessToken } from "src/auth/types/userToken";
import { clearStoredPracticeId } from "src/auth/utils/practice";
import { UUID } from "src/common/types/primitives";
import { noop } from "src/common/utils";

export type Tokens = {
  [userId: string]: string | null;
};

export const saveCurrentUserId = (userId: UUID): void => {
  localStorage.setItem(CURRENT_USER_ID_STORAGE_KEY, userId);
  getCurrentUserId();
};

export const getCurrentUserId: () => string | null = () => {
  const userId = localStorage.getItem(CURRENT_USER_ID_STORAGE_KEY);
  return userId;
};

const removeCurrentUserId = () => {
  localStorage.removeItem(CURRENT_USER_ID_STORAGE_KEY);
  getCurrentUserId();
};

export async function clearCurrentUserTokens() {
  const currentUserId = getCurrentUserId();
  removeToken(currentUserId);
  removeRefreshToken(currentUserId);
  removeCurrentUserId();
  if (currentUserId) {
    await clearStoredPracticeId(currentUserId);
  }
}

export const getTokens = (): Tokens => {
  const tokens = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  const xTokens = tokens ? (JSON.parse(tokens) as Tokens) : {};
  return xTokens;
};

export const getRefreshTokens = (): Tokens => {
  const tokens = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  const xTokens = tokens ? (JSON.parse(tokens) as Tokens) : {};
  return xTokens;
};

export const setTokens = (userId: UUID, token: string): void => {
  const tokens = getTokens();
  localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, JSON.stringify({ ...tokens, [userId]: token }));
  getTokens();
  saveCurrentUserId(userId);
};

export const setRefreshToken = (
  userId: UUID,
  token: string,
  keepAliveCallback: () => unknown = noop
): void => {
  const tokens = getRefreshTokens();
  localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, JSON.stringify({ ...tokens, [userId]: token }));
  getRefreshTokens();
  keepAliveCallback();
  saveCurrentUserId(userId);
};

const removeToken = (userId: UUID | null) => {
  if (!userId) return;
  const tokens = getTokens();
  const { [userId]: removedUserToken, ...remainingTokens } = tokens;
  localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, JSON.stringify({ ...remainingTokens }));
  getTokens();
};

const removeRefreshToken = (userId: UUID | null) => {
  if (!userId) return;
  const tokens = getRefreshTokens();
  const { [userId]: removedUserToken, ...remainingTokens } = tokens;
  localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, JSON.stringify({ ...remainingTokens }));
  getRefreshTokens();
};

export const getTokenForCurrentUser = (): string | null => {
  const userId = getCurrentUserId();
  const tokens = getTokens();

  return userId ? tokens[userId] : null;
};

export const getRefreshTokenForCurrentUser = (): string | null => {
  const userId = getCurrentUserId();
  const tokens = getRefreshTokens();

  return userId ? tokens[userId] ?? null : null;
};

export const hasOktaToken = (): boolean => {
  const token = getTokenForCurrentUser();
  if (token === null) return false;

  const decodedToken = jwtDecode<JwtPayload>(token);
  return decodedToken.iss?.includes("okta") ?? false;
};

export const getPracticeIdsFromAccessToken = (accessToken?: string): string[] => {
  if (isNil(accessToken)) {
    return [];
  }
  const { practiceId, practiceIds } = jwtDecode<AccessToken>(accessToken);

  if (practiceIds !== undefined) {
    return practiceIds.split(",");
  }

  if (practiceId !== undefined) {
    return [practiceId];
  }

  return [];
};

export const hasCognitoToken = (): boolean => {
  const token = getTokenForCurrentUser();
  if (token === null) return false;

  const decodedToken = jwtDecode<JwtPayload>(token);
  return decodedToken.iss?.includes("cognito") ?? false;
};

export const isCurrentUserTokenExpiringSoon = (): boolean => {
  const currentUserToken = getTokenForCurrentUser();
  if (currentUserToken === null) return false;

  try {
    const { exp } = jwtDecode<JwtPayload>(currentUserToken);
    // JWT gives exp in seconds.
    const expInMillis = (exp ?? 0) * 1000;
    const oneMinuteBufferInMillis = 1000 * 60;
    return Date.now() >= expInMillis - oneMinuteBufferInMillis;
  } catch {
    // Error means the token is not JWT so don't do anyhting to it.
    return false;
  }
};

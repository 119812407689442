import { isEqual, mapValues, memoize } from "lodash";
import React, {
  useEffect,
  useReducer,
  useState,
  useCallback,
  Dispatch,
  useMemo,
  useRef,
} from "react";
import { TextStyle, StyleProp, ScrollView, View, ViewStyle } from "react-native";

import { TriStateSortDirection } from "src/common/types/sorting";
import { tailwind } from "src/foundations/styles";
import { LinearGradient } from "src/foundations/ui";

import TableRow from "./TableRow";

const GRADIENT_WIDTH = 74;

// TODO: Support passing down hover state to cell
type CellComponentArgs = {
  rowIsHovered?: boolean;
};

export function tableCellValueIsComponentValue(value: TableRowValue): value is ComponentValue {
  return (
    typeof value !== "string" && typeof value !== "number" && value !== null && value !== undefined
  );
}

type PrimitiveTableValues = string | number | null | undefined;
type ComponentValue = {
  // TODO: Support passing down hover state to cell; Support passing undefined component
  // Component: React.ReactNode;
  Component: React.ReactNode | ((args: CellComponentArgs) => React.ReactNode) | undefined;
  value: PrimitiveTableValues;
};
// Prop types
// TODO: Export this to be used by RulesTable
export type TableRowValue = ComponentValue | PrimitiveTableValues;

export type TableRowProp<T extends string = string> = Record<T, TableRowValue>;

export type TableColProp<T extends string = string> = {
  name: string; // Header text for column
  field: T; // Field name of data that will be in rows for this column
  textStyle?: TextStyle; // Text style to be applied to all cells in this column
  width?: number;
  sortable?: boolean;
  decorator?: React.ReactNode;
  minimal?: boolean;
};

// TODO: added generics
type BaseTableProps<TRowProp extends TableRowProp, TColProp extends TableColProp> = {
  style?: StyleProp<ViewStyle>;
  /* The data in these rows does _not_ have to be unique UNLESS
   * `hasSelectableRows={true}`. See the comment on hasSelectableRows for
   * more instructions
   */
  // TODO: updated to generic type
  rows: TRowProp[];
  // TODO: updated to generic type
  cols: TColProp[];
  // Columns that should stick to the left while scrolling horizontally
  stickyCols?: TColProp[];
  accessibilityLabel?: string;
  onRowPress?: (row: TRowProp) => void;
  // TODO: add empty state support
  emptyContent?: React.ReactNode;
  // TODO: add row hovered state propagation
  onRowHovered?: (rowIndex: number) => void;
  // TODO: add row end adornment capability
  renderRowEndAdornment?: (rowIndex: number) => React.ReactNode;
  sortedColumns?: Array<{ field: string; direction: TriStateSortDirection }>;
  onHeaderColumnClick?: (field: string, direction?: TriStateSortDirection) => void;
  minimal?: boolean;
};

// TODO: added generics
type NonSelectableTableProps<
  TRowProp extends TableRowProp,
  TColProp extends TableColProp
> = BaseTableProps<TRowProp, TColProp> & {
  hasSelectableRows?: false;
};

// TODO: added generics
type SelectableTableProps<
  TRowProp extends TableRowProp,
  TColProp extends TableColProp
> = BaseTableProps<TRowProp, TColProp> & {
  /* If true, this assumes data passed in is unique. If the data you're rendering
   * could be non-unique, you can pass a unique field that doesn't get rendered
   * like `key` or `id`. as long as the key doesn't match one of the `field`s in
   * the cols definitition, it won't be rendered
   */
  hasSelectableRows: true;
  tableState: TableState;
  updateTableState: Dispatch<TableAction>;
};

// TODO: added generics
export type TableProps<TRowProp extends TableRowProp, TColProp extends TableColProp> =
  | NonSelectableTableProps<TRowProp, TColProp>
  | SelectableTableProps<TRowProp, TColProp>;

// State types
export type TableState<T extends string = string> = {
  selectedRows: TableRowProp<T>[];
  selectAllChecked: boolean;
};

export enum TableActionType {
  SelectAllToggle,
  SelectRowChange,
  ClearAllSelections,
}

export type TableAction =
  | { type: TableActionType.SelectAllToggle; payload: TableRowProp[] }
  | { type: TableActionType.ClearAllSelections }
  | { type: TableActionType.SelectRowChange; payload: TableRowProp };

function mapTableRowValues(row: TableRowProp) {
  return mapValues(row, (val) => {
    if (val && typeof val === "object" && "Component" in val) {
      return val.value;
    }
    return val;
  });
}

// State reducer
export const tableReducer = (state: TableState, action: TableAction): TableState => {
  const { selectAllChecked, selectedRows } = state;
  switch (action.type) {
    case TableActionType.SelectAllToggle: {
      const rows = action.payload.map(mapTableRowValues);
      const isSelectingAll = !selectAllChecked;
      // If selecting all, set the selection to all rows, otherwise, unselect all rows
      const newSelection = isSelectingAll ? rows : [];
      return { ...state, selectAllChecked: isSelectingAll, selectedRows: newSelection };
    }
    case TableActionType.ClearAllSelections: {
      return { ...state, selectAllChecked: false, selectedRows: [] };
    }
    case TableActionType.SelectRowChange: {
      const row = mapTableRowValues(action.payload);
      // If the row does not exist in the current selection, it is now being selected
      const isSelectingRow = !isRowSelected(row, selectedRows);
      // If the row is being unselected, remove it from selectedRows. Otherwise,
      // add it to selectedRows
      const newSelection = isSelectingRow
        ? [...selectedRows, row]
        : selectedRows.filter((selectedRow) => !isEqual(row, selectedRow));
      // When selecting a row, selectAllChecked must have been false because at
      // least one row was selectable. We do not change the selectAllChecked
      // state to true since it was not explicitly selected. When deselecting a
      // row, selectAllChecked must be set to false as not all items are
      // selected
      return {
        ...state,
        selectAllChecked: false,
        selectedRows: newSelection,
      };
    }
    default:
      return state;
  }
};

export const initialTableState: TableState = {
  selectedRows: [],
  selectAllChecked: false,
};

export function useTableSelectionState<T extends string = string>(): [
  TableState<T>,
  Dispatch<TableAction>
] {
  const [state, dispatch] = useReducer(tableReducer, initialTableState);
  return [state, dispatch];
}

// TODO: added generics
export default function Table<TRowProp extends TableRowProp, TColProp extends TableColProp>(
  props: TableProps<TRowProp, TColProp>
) {
  const {
    style,
    rows,
    cols,
    stickyCols,
    hasSelectableRows,
    accessibilityLabel,
    onRowPress,
    sortedColumns,
    onHeaderColumnClick,
    minimal,
  } = props;
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [scrolledToEnd, setScrolledToEnd] = useState<boolean>(false);

  // Track row hovers to sync hover state between sticky column rows and
  // scrollable column rows
  const [hoveredRowId, setHoveredRowId] = useState<number>(-1);
  const onRowHoverChange = useCallback(
    (hovered, rowId) => {
      const rowIdHovered = hovered ? rowId : -1;
      setHoveredRowId(rowIdHovered);
      // TODO: added ability to propagate the row hovered
      if (props.onRowHovered) {
        props.onRowHovered(rowIdHovered);
      }
    },
    [props.onRowHovered]
  );

  // Track state for showing gradient when table can be scrolled
  const [tableHeight, setTableHeight] = useState<number>(0);
  const rowHeights = useRef<Record<number, number>>({});
  const [scrollViewWidth, setScrollViewWidth] = useState<number>(0);
  const [scrollContentWidth, setScrollContentWidth] = useState<number>(0);

  useEffect(() => {
    // Check if the content can't be scrolled
    setScrolledToEnd(scrollViewWidth >= scrollContentWidth);
  }, [scrollViewWidth, scrollContentWidth]);

  if (!cols.length) {
    // We cannot render the table without cols
    console.warn("Cannot render table because cols list is empty");
    return null;
  }

  const headerRowStyle = useMemo(
    () =>
      minimal
        ? tailwind("h-11 flex-row items-center")
        : tailwind("h-16 flex-row items-center bg-gray-50"),
    [minimal]
  );
  const getBodyRowStyle = useMemo(
    () =>
      memoize((h: number) =>
        minimal
          ? [tailwind("flex-row items-center"), { minHeight: h ?? 44 }]
          : [tailwind("flex-row items-center border-top-shadow"), { minHeight: h ?? 64 }]
      ),
    [minimal]
  );

  const handleHeaderRowSelect = useCallback(() => {
    if (props.hasSelectableRows) {
      props.updateTableState({ type: TableActionType.SelectAllToggle, payload: rows });
    }
  }, []);

  // TODO: Fix in enterprise. The callback wasn't updated because `props.updateTableState` was not
  // in the dependency array even though it is used inside. TS seems unable to guard against it
  // since it doesn't know that props is SelectableTable until we use it inside the callback. To see
  // what I mean, put props.updateTableState directly in the dependency array.
  const updateTableStateFunction = props.hasSelectableRows ? props.updateTableState : undefined;
  const handleBodyRowSelect = useCallback(
    (row?: TRowProp) => {
      if (props.hasSelectableRows && row !== undefined) {
        props.updateTableState({ type: TableActionType.SelectRowChange, payload: row });
      }
    },
    [updateTableStateFunction]
  );

  // Render a table section for a set of columns. For example, render the sticky
  // columns, or render the scrollable columns
  const renderTableSection = (
    sectionCols: TableColProp[],
    sectionHasSelectableRows: boolean | undefined
  ) => {
    let selectAllChecked, updateTableState: Dispatch<TableAction>, selectedRows: TableRowProp[];
    if (props.hasSelectableRows) {
      selectedRows = props.tableState.selectedRows;
      updateTableState = props.updateTableState;
      selectAllChecked = props.tableState.selectAllChecked;
    }

    return (
      <>
        {/* Header row */}
        <TableRow
          style={headerRowStyle}
          selectable={sectionHasSelectableRows}
          selected={selectAllChecked}
          selectDisabled={!rows.length}
          onSelectChange={handleHeaderRowSelect}
          checkboxAccessibilityLabel={selectAllChecked ? "Unselect all rows" : "Select all rows"}
          isHeader
          cols={sectionCols}
          sortedColumns={sortedColumns}
          onHeaderColumnClick={onHeaderColumnClick}
          minimal={minimal}
        />

        {/* Rows of data */}
        {rows.map((row, rowIndex) => (
          <View
            key={rowIndex}
            onLayout={(event) => {
              rowHeights.current[rowIndex] = Math.max(
                event.nativeEvent.layout.height,
                rowHeights.current[rowIndex] || 44
              );
            }}
          >
            <TableRow
              row={row}
              rowId={rowIndex}
              style={getBodyRowStyle(rowHeights.current[rowIndex])}
              selectable={sectionHasSelectableRows}
              selected={selectedRows && isRowSelected(mapTableRowValues(row), selectedRows)}
              onSelectChange={handleBodyRowSelect}
              onHoverChange={onRowHoverChange}
              hoveredOverride={rowIndex === hoveredRowId}
              onRowPress={onRowPress}
              sortedColumns={sortedColumns}
              cols={sectionCols}
              minimal={minimal}
            />
          </View>
        ))}
      </>
    );
  };

  const tableStyle = minimal
    ? tailwind("bg-white rounded-lg shadow shadow-size-m overflow-hidden p-4")
    : tailwind("bg-white rounded-lg shadow shadow-size-m overflow-hidden");
  const tableA11yRole = "table";
  const tableA11yLabel = accessibilityLabel || getDefaultAccessibilityLabel(hasSelectableRows);

  // Render
  return rows.length === 0 && props.emptyContent !== undefined && minimal ? (
    <View
      style={[tableStyle, style]}
      // @ts-expect-error TS2769: No overload matches this call.
      accessibilityRole={tableA11yRole}
      accessibilityLabel={tableA11yLabel}
    >
      {props.emptyContent}
    </View>
  ) : rows.length === 0 && props.emptyContent !== undefined ? (
    <View
      style={[tableStyle, style]}
      // @ts-expect-error TS2769: No overload matches this call.
      accessibilityRole={tableA11yRole}
      accessibilityLabel={tableA11yLabel}
    >
      <View style={tailwind("h-16 w-full bg-gray-50 border-b border-gray-100")} />
      <View style={{ minHeight: 536 }}>{props.emptyContent}</View>
    </View>
  ) : (
    <View
      style={[tableStyle, tailwind("flex-row"), style]}
      // @ts-expect-error TS2769: No overload matches this call.
      accessibilityRole={tableA11yRole}
      accessibilityLabel={tableA11yLabel}
      onLayout={(e) => setTableHeight(e.nativeEvent.layout.height)}
    >
      {/* Sticky columns */}
      {stickyCols && (
        <View
          style={[
            scrolled
              ? tailwind("border-right-shadow shadow shadow-size-m-light")
              : tailwind("border-right-shadow"),
            {
              flexGrow: stickyCols.length,
            },
          ]}
        >
          {renderTableSection(stickyCols, hasSelectableRows)}
        </View>
      )}
      {/* Scrollable columns */}
      <ScrollView
        style={{ flexGrow: stickyCols ? cols.length : 1, zIndex: -1 }}
        accessible
        contentContainerStyle={[tailwind("flex-col flex-grow")]}
        horizontal
        onScroll={(e) => {
          const { layoutMeasurement, contentOffset, contentSize } = e.nativeEvent;
          setScrolled(contentOffset.x > 0);
          setScrolledToEnd(layoutMeasurement.width + contentOffset.x >= contentSize.width);
        }}
        onLayout={(e) => setScrollViewWidth(e.nativeEvent.layout.width)}
        onContentSizeChange={(contentWidth) => setScrollContentWidth(contentWidth)}
        scrollEventThrottle={16}
        accessibilityLabel="Scrollable columns"
        persistentScrollbar={false}
      >
        {renderTableSection(cols, !stickyCols && hasSelectableRows)}
      </ScrollView>
      {/* Show right linear gradient when scrollable */}
      <LinearGradient
        style={[
          tailwind("absolute right-0 pointer-events-none"),
          { opacity: scrolledToEnd ? 0 : 1 },
        ]}
        height={tableHeight}
        width={GRADIENT_WIDTH}
        x1={0}
        x2={GRADIENT_WIDTH}
        y1={tableHeight}
        y2={tableHeight}
      />
    </View>
  );
}

// Helper functions
const isRowSelected = (row: TableRowProp, selectedRows: TableRowProp[]) => {
  return !!selectedRows.find((selectedRow) => isEqual(row, selectedRow));
};

const getDefaultAccessibilityLabel = (hasSelectableRows?: boolean) => {
  return hasSelectableRows ? "Data table with selectable rows" : "Data table";
};

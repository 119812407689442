import { carbonActivePracticeIdKey } from "@ch/data/practices";
import { visitIdHeaderKey, visitorIdHeaderKey } from "@ch/data/visitor";

export const AUTH_TOKEN_STORAGE_KEY = "apiTokenV2";
export const REFRESH_TOKEN_STORAGE_KEY = "refreshToken";
export const CURRENT_USER_ID_STORAGE_KEY = "currentUserId";

export const CarbonHeaders = {
  AUTHORIZATION: "authorization",
  APP_TYPE: "carbonapptype",
  PLATFORM_TYPE: "carbonplatformtype",
  APP_VERSION: "carbonappversion",
  DEVICE_ID: "carbondeviceid",
  VISIT_ID: visitIdHeaderKey,
  VISITOR_ID: visitorIdHeaderKey,
  PRACTICE_ID: carbonActivePracticeIdKey,
} as const;

export const placeholderPracticeId = "00000000-0000-0000-0000-000000000000";

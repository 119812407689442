/*
Careful!! The homepage (carbon-website-next, which serves carbonhealth.com) and Patient
app rely on these same visitId and visitorId keys existing in localStorage and having
UUID values to track flows globally. These key names and value formats _must not change_
without coordination between the two projects.
*/
export const visitIdStorageKey = "visitId";
export const visitorIdStorageKey = "visitorId";

export const visitIdHeaderKey = "CarbonVisitId";
export const visitorIdHeaderKey = "CarbonVisitorId";

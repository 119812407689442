import isSet from "lodash/isSet";
import { useRouter } from "next/router";
import { PropsWithChildren, createContext, useCallback, useEffect, useState } from "react";

import { BillFilters } from "src/bills/types/billFilters";
import { ColumnSort } from "src/common/types/sorting";
import { noop } from "src/common/utils";
import { decodeUrlData } from "src/common/utils/decodeUrlData";
import { encodeUrlData } from "src/common/utils/encodeUrlData";
import { logError } from "src/common/utils/reporting";

export type UrlFragmentData = {
  filters?: BillFilters;
  sort?: ColumnSort[];
  pagination?: {
    after?: string;
    before?: string;
    first: number;
  };
};

export type QueueStateContext = {
  queuesMap: Record<string, UrlFragmentData>;
  setQueueState: (queueId: string, urlData: UrlFragmentData) => void;
};

const defaultQueueStateContext: QueueStateContext = {
  queuesMap: {},
  setQueueState: () => noop,
};

export const QueueStateContext = createContext<QueueStateContext>(defaultQueueStateContext);

const filterFieldsOfTypeSet = [
  "responsibleParty",
  "locationType",
  "claimAdjustmentReasonCodes",
  "practiceSlugs",
];

export const QueueStateProvider = (props: PropsWithChildren<unknown>) => {
  const router = useRouter();
  const dataFromUrl = router.asPath.split("#")[1];

  const [queuesState, setQueuesState] = useState<QueueStateContext>(defaultQueueStateContext);

  const updateUrlWithQueueState = useCallback(
    async (queueId: string, urlData: UrlFragmentData) => {
      try {
        let transformedFilters = urlData.filters;
        if (urlData && urlData.filters) {
          transformedFilters = Object.fromEntries(
            Object.entries(urlData.filters).map(([key, value]) => {
              if (filterFieldsOfTypeSet.includes(key) && isSet(value)) {
                return [key, [...value]];
              }
              return [key, value];
            })
          );
        }

        const encodedUrlData = await encodeUrlData({
          [queueId]: { ...urlData, filters: transformedFilters },
        });

        router.replace(`${router.asPath.split("#")[0]}#${encodedUrlData}`);
      } catch (err) {
        logError(err, {
          customMessage: `Unable to encode url data`,
          extraData: { urlData: JSON.stringify({ [queueId]: urlData }) },
        });
      }
    },
    [router]
  );

  const setQueueState = (queueId: string, urlData: UrlFragmentData) => {
    setQueuesState({
      ...queuesState,
      queuesMap: { ...queuesState.queuesMap, [queueId]: urlData },
    });

    updateUrlWithQueueState(queueId, urlData);
  };

  const initializeQueueStateFromUrl = useCallback(
    async () => {
      if (!router.isReady) return;
      if (dataFromUrl) {
        const data = await decodeUrlData(dataFromUrl);

        if (data === null || data === undefined) {
          router.replace(`${router.asPath.split("#")[0]}`);
        } else {
          Object.keys(data).forEach((key) => {
            //@ts-expect-error will sort later
            if (data[key].filters) {
              //@ts-expect-error will sort later
              data[key].filters = Object.fromEntries(
                //@ts-expect-error will sort later
                Object.entries(data[key].filters).map(([key, value]) => {
                  if (filterFieldsOfTypeSet.includes(key)) {
                    //@ts-expect-error will sort later
                    return [key, new Set(value)];
                  }
                  return [key, value];
                })
              );
            }
          });

          setQueuesState({
            ...queuesState,
            queuesMap: { ...queuesState.queuesMap, ...data },
          });
        }
      }
    },
    // we only care about this value
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady]
  );

  useEffect(() => {
    initializeQueueStateFromUrl();
  }, [initializeQueueStateFromUrl]);

  return (
    <QueueStateContext.Provider value={{ ...queuesState, setQueueState }}>
      {props.children}
    </QueueStateContext.Provider>
  );
};

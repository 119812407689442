import { useRouter } from "next/router";
import { memo, useState, useCallback, FocusEvent } from "react";
import { View } from "react-native";

import { useLocalPracticeId } from "src/auth/utils/practice";
import { tailwind } from "src/foundations/styles";
import { Icon, Paragraph } from "src/foundations/ui";
import colors from "src/foundations/ui/colors.json";
import PressableHighlight from "src/ui/components/PressableHighlight";
import SearchInput from "src/ui/components/SearchInput";

export const userInstruction = 'Click here or press "Enter" to search on Bills page';

const GlobalSearch: React.FC = () => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const activePracticeId = useLocalPracticeId();

  const goToSearchPage = useCallback(
    (query: string) => {
      setSearchTerm("");
      router.push(`/${activePracticeId}/bills?q=${query}`);
    },
    [router, activePracticeId]
  );

  const handleClickGoToSearchPage = useCallback(() => {
    setIsOpen(false);
    goToSearchPage(searchTerm);
  }, [goToSearchPage, searchTerm]);

  return (
    <View
      // TS says onBlur is not a valid View props, but it seems to work just fine for web.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onBlur={(e: FocusEvent) => {
        const focusLeftThisComponent = !e.currentTarget?.contains(e.relatedTarget);
        if (focusLeftThisComponent) setIsOpen(false);
      }}
    >
      <SearchInput
        accessibilityLabel="Bill quick search"
        value={searchTerm}
        onInputChange={setSearchTerm}
        placeholder={
          isOpen ? "Search bills by Patient Name, MRN, Claim ID, or Bill ID" : "Search bills"
        }
        onSubmitEditing={(e) => goToSearchPage(e.nativeEvent.text)}
        onFocus={() => setIsOpen(true)}
        width={isOpen ? 480 : undefined}
        accessibilityDescribedBy="clickOrEnterToSearch"
      />
      <View
        // @ts-expect-error TS2769: No overload matches this call.
        accessibilityRole="presentation"
        style={[
          !isOpen && tailwind("hidden"),
          tailwind(
            "absolute z-modal mt-2 top-full w-full bg-white rounded-lg p-2 shadow shadow-size-s"
          ),
        ]}
      >
        {/* TODO: Consider making a proper listbox component with the keyboard nav. For now, this
        will do */}
        {/** @ts-expect-error TS2769: No overload matches this call. */}
        <View accessibilityRole="listbox">
          <PressableHighlight
            style={tailwind("p-2 rounded-lg flex-row justify-between items-center")}
            // @ts-expect-error TS2769: No overload matches this call.
            accessibilityRole="option"
            onPress={handleClickGoToSearchPage}
            nativeID="clickOrEnterToSearch"
          >
            <Paragraph level={2} color={colors.gray["400"]}>
              {userInstruction}
            </Paragraph>
            <Icon name="keyboard-return-key" size={16} />
          </PressableHighlight>
        </View>
      </View>
    </View>
  );
};

export default memo(GlobalSearch) as typeof GlobalSearch;

import isNil from "lodash/isNil";

export function throwIfUnauthenticated<T extends { __typename?: string | null }>(
  gqlType?: T | null
): Extract<T, { __typename?: "BillingUser" | null }> | undefined | null {
  if (gqlType?.__typename === "BillingUser" || isNil(gqlType)) {
    return gqlType as Extract<T, { __typename?: "BillingUser" | null }> | undefined | null;
  }

  throw new Error("Unauthenticated");
}

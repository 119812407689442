import { makeVar, useReactiveVar } from "@apollo/client";
import {
  clearLoggedInPracticeIds,
  getActiveLoggedInPracticeId,
  resetLoggedInPracticeIds,
  setActivePracticeId,
} from "@ch/data/practices";

// https://www.apollographql.com/docs/react/local-state/reactive-variables/
const localPracticeId = makeVar<string | null>(null);

export function useLocalPracticeId() {
  return useReactiveVar(localPracticeId);
}

export function getLocalPracticeId() {
  return localPracticeId();
}

export function setLocalPracticeId(practiceId: string | null) {
  localPracticeId(practiceId);
}

export async function getStoredPracticeId() {
  const practiceId = await getActiveLoggedInPracticeId();
  if (getLocalPracticeId() === null) {
    setLocalPracticeId(practiceId);
  }
  return await getActiveLoggedInPracticeId();
}

export async function setStoredPracticeId(practiceId: string, userId: string) {
  setLocalPracticeId(practiceId);
  await resetLoggedInPracticeIds({ [practiceId]: userId });
  return setActivePracticeId(practiceId);
}

export async function clearStoredPracticeId(userId: string) {
  setLocalPracticeId(null);
  return clearLoggedInPracticeIds(userId);
}

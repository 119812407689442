import Svg, { Path } from "react-native-svg";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export const ChevronLeftIcon: React.FC<IconProps> = (props) => {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg height={props.size} width={props.size} fill="none" viewBox="0 0 16 16">
      <Path
        d="m11 14-5.87946-5.71733c-.03821-.0371-.06853-.08117-.08921-.12967s-.03133-.10049-.03133-.153.01065-.1045.03133-.153.051-.09256.08921-.12967l5.87946-5.71733"
        stroke={color ?? colors.gray[800]}
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </Svg>
  );
};

export default ChevronLeftIcon;

import * as React from "react";
import Svg, { Path } from "react-native-svg";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function ClipboardIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 13 11" fill="none">
      <Path
        d="M1 6.14286L4.47863 10L12 1"
        // TODO: Color should be neutral by default. App-specific color should be passed via a theme
        // or configuration.
        // stroke={color || colors.green.dark}
        stroke={color || colors.gray[800]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

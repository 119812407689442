import {
  confirmResetPassword,
  confirmSignIn as cognitoConfirmSignIn,
  fetchAuthSession,
  resetPassword,
  signIn as cognitoSignIn,
  SignInOutput,
  signInWithRedirect,
  signOut as cognitoSignOut,
} from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import isNil from "lodash/isNil";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";

import { LoginMethodResponse } from "src/auth/api/getLoginMethod";
import { initializeCognito } from "src/auth/utils/cognito";
import {
  clearCurrentUserTokens,
  getCurrentUserId,
  getTokenForCurrentUser,
} from "src/auth/utils/userToken";
import { logError } from "src/common/utils/reporting";

import { AuthProviderDispatch } from "./authProviderStore";

type SignInCredentials = {
  email: string;
  password: string;
};

type UseCognitoProps = {
  dispatch: AuthProviderDispatch;
  onStartPracticeInitFlow: (props: {
    accessToken: string;
    mountedRef: { mounted: boolean };
    restoringExistingSession: boolean;
  }) => Promise<void>;
  onSignOut: () => void;
};
export type UseCognitoData = {
  /**
   * @param loginMethodResponse
   * @param credentials
   */
  signInWithCognito: (
    loginMethodResponse?: LoginMethodResponse,
    credentials?: SignInCredentials
  ) => Promise<void>;
  /**
   * Sign in with cognito using a new password.
   * This is used when user is required to change their password.
   * @param password new password
   */
  signInWithCognitoNewPassword: (email: string, password: string) => Promise<void>;
  signInWithTwoFactorAuthCode: (email: string, code: string) => Promise<void>;
  startPasswordResetFlowWithCognito: (email: string) => Promise<void>;
  resetPasswordWithCognito: (
    confirmationCode: string,
    username: string,
    newPassword: string
  ) => Promise<void>;

  signOutWithCognito: () => Promise<void>;
  cancelResetPasswordFlow: () => Promise<void>;
  /**
   * Call when user bail out of auth flow
   */
  cancelTwoFactorAuth: () => Promise<void>;
  /**
   * Call when user bail out of first time password setup
   */
  cancelPasswordSetup: () => Promise<void>;
  /**
   * called to either sign in after a successful login or to restore an existing session on page load
   */
  tryRestoreCognitoSession: () => Promise<void>;
};

initializeCognito();
const internalCognitoHubChannel = "CognitoAuthProvider";
export default function useCognito(props: UseCognitoProps): UseCognitoData {
  const { dispatch, onSignOut, onStartPracticeInitFlow } = props;

  const router = useRouter();

  const initCognitoUserSessionIfAuthenticated = useCallback(
    async function ({
      restoringExistingSession = false,
      mountedRef = { mounted: true },
      accessToken = undefined,
    }: {
      restoringExistingSession?: boolean;
      mountedRef?: { mounted: boolean };
      accessToken?: string;
    }) {
      if (accessToken === undefined) {
        if (restoringExistingSession) {
          await clearCurrentUserTokens();
          mountedRef.mounted &&
            dispatch({
              type: "GET_STORED_TOKEN_FAILED",
            });
        }

        !restoringExistingSession &&
          mountedRef.mounted &&
          dispatch({
            type: "LOGIN_FAILED",
            payload: {
              error: new Error("Authentication failed."),
            },
          });
        return;
      }

      await onStartPracticeInitFlow({
        accessToken,
        mountedRef,
        restoringExistingSession,
      });
    },
    [dispatch, onStartPracticeInitFlow]
  );

  // process sign in event
  useEffect(() => {
    let mounted = true;

    function handleError(error: Error): void {
      dispatch({
        type: "error",
        payload: { error },
      });
    }

    const unsubscribeSignInCb = Hub.listen("auth", async ({ payload: { event } }) => {
      if (event === "signedIn") {
        const accessToken = (await fetchAuthSession())?.tokens?.accessToken?.toString();
        if (accessToken !== null) {
          initCognitoUserSessionIfAuthenticated({
            restoringExistingSession: false,
            mountedRef: { mounted },
            accessToken,
          }).catch(handleError);
        }
        return;
      }

      if (event === "signInWithRedirect_failure") {
        dispatch({
          type: "LOGIN_FAILED",
          payload: {
            error: new Error("Authentication failed."),
          },
        });
        return;
      }
    });

    const unsubscribeRestoreCb = Hub.listen(internalCognitoHubChannel, ({ payload: { event } }) => {
      if (event === "restore") {
        const accessToken = getTokenForCurrentUser();
        if (accessToken !== null) {
          initCognitoUserSessionIfAuthenticated({
            restoringExistingSession: true,
            mountedRef: { mounted },
            accessToken,
          }).catch(handleError);
        }
        return;
      }
    });

    return () => {
      mounted = false;
      unsubscribeSignInCb();
      unsubscribeRestoreCb();
    };
  }, [dispatch, initCognitoUserSessionIfAuthenticated]);

  const tryRestoreCognitoSession = useCallback(async () => {
    Hub.dispatch(internalCognitoHubChannel, { event: "restore" });
  }, []);

  const startPasswordResetFlowWithCognito = useCallback(
    async (email: string) => {
      try {
        const response = await resetPassword({ username: email });

        dispatch({
          type: "PASSWORD_RESET_NEEDED",
          payload: {
            twoFactorDestination: response.nextStep.codeDeliveryDetails?.destination,
            twoFactorMedium: response.nextStep.codeDeliveryDetails?.deliveryMedium,
          },
        });
      } catch (error) {
        if (error instanceof Error) {
          dispatch({
            type: "ERROR",
            payload: { error },
          });
        }
      }
    },
    [dispatch]
  );

  const handleCognitoPasswordSignInResult = useCallback(
    async (response: SignInOutput, data?: { email: string }) => {
      switch (response.nextStep?.signInStep) {
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED": {
          dispatch({
            type: "PASSWORD_SETUP_NEEDED",
          });
          return;
        }
        case "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE": {
          dispatch({
            type: "LOGIN_FAILED",
            payload: {
              error: new Error("Authentication failed. Custom challenge required."),
            },
          });
          return;
        }
        case "CONFIRM_SIGN_IN_WITH_TOTP_CODE": {
          dispatch({
            type: "LOGIN_FAILED",
            payload: {
              error: new Error("Authentication failed. TOTP Code required."),
            },
          });
          return;
        }
        case "CONTINUE_SIGN_IN_WITH_TOTP_SETUP": {
          dispatch({
            type: "LOGIN_FAILED",
            payload: {
              error: new Error("Authentication failed. TOTP setup required."),
            },
          });
          return;
        }
        case "CONFIRM_SIGN_IN_WITH_SMS_CODE": {
          dispatch({
            type: "2FA_NEEDED",
            payload: {
              twoFactorDestination: response.nextStep.codeDeliveryDetails?.destination,
              twoFactorMedium: response.nextStep.codeDeliveryDetails?.deliveryMedium,
            },
          });
          return;
        }
        case "CONTINUE_SIGN_IN_WITH_MFA_SELECTION": {
          dispatch({
            type: "LOGIN_FAILED",
            payload: {
              error: new Error("Authentication failed. MFA type selection is not supported."),
            },
          });
          return;
        }
        case "RESET_PASSWORD": {
          if (data?.email === undefined) {
            dispatch({
              type: "LOGIN_FAILED",
              payload: {
                error: new Error(
                  "Authentication failed. Password reset is required but no email found."
                ),
              },
            });
            return;
          }

          await startPasswordResetFlowWithCognito(data.email);
          return;
        }

        case "CONFIRM_SIGN_UP": {
          dispatch({
            type: "LOGIN_FAILED",
            payload: {
              error: new Error("Authentication failed. User registration is not complete."),
            },
          });
          return;
        }

        case "DONE": {
          // Hub callback will handle this case
          return;
        }

        default:
          logError(new Error("Unknown cognito sign in step"), {
            customMessage: `Unknown cognito sign in step`,
            extraData: { response },
          });
          return;
      }
    },
    [dispatch, startPasswordResetFlowWithCognito]
  );

  const signInWithCognito = useCallback(
    async function signInWithCognito(
      loginMethodResponse?: LoginMethodResponse,
      credentials?: SignInCredentials
    ) {
      const { idpClientLogin, isExistingCognitoUser } = loginMethodResponse ?? {};

      const session = await fetchAuthSession();
      if (!isNil(session.tokens)) {
        // we're somehow logged in and need to sign out for a fresh sign in.
        await cognitoSignOut();
      }
      try {
        if (!idpClientLogin) {
          if (!credentials) {
            return;
          }
          // if we're trying to sign in to an existing cognito user, use default SRP (https://docs.amplify.aws/javascript/build-a-backend/auth/switch-auth/)
          // otherwise, use USER_PASSWORD_AUTH (necessary for user migration to cognito -> https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-import-using-lambda.html)
          const response = isExistingCognitoUser
            ? await cognitoSignIn({
                username: credentials.email,
                password: credentials.password,
              })
            : await cognitoSignIn({
                username: credentials.email,
                password: credentials.password,
                options: {
                  authFlowType: "USER_PASSWORD_AUTH",
                },
              });
          await handleCognitoPasswordSignInResult(response, credentials);
          return;
        }
        await signInWithRedirect({
          provider: { custom: idpClientLogin },
          customState: window?.location?.origin,
        });
        // Hub callback will handle this case
      } catch (e) {
        if (e instanceof Error && e.name === "UserAlreadyAuthenticatedException") {
          const accessToken = (await fetchAuthSession())?.tokens?.accessToken?.toString();
          if (accessToken === undefined) {
            dispatch({
              type: "ERROR",
              payload: { error: new Error("Authentication failed.") },
            });
            return;
          } else {
            await initCognitoUserSessionIfAuthenticated({
              restoringExistingSession: false,
              mountedRef: { mounted: true },
              accessToken,
            });
            return;
          }
        }

        dispatch({
          type: "LOGIN_FAILED",
          payload: { error: e },
        });
      }

      return;
    },
    [dispatch, handleCognitoPasswordSignInResult, initCognitoUserSessionIfAuthenticated]
  );
  const resetPasswordWithCognito = useCallback(
    async (confirmationCode: string, username: string, newPassword: string) => {
      try {
        await confirmResetPassword({
          confirmationCode,
          username,
          newPassword,
        });
      } catch (error) {
        if (error instanceof Error) {
          dispatch({
            type: "PASSWORD_RESET_FAILED",
            payload: { error },
          });
        }
        return;
      }

      try {
        const response = await cognitoSignIn({
          username,
          password: newPassword,
        });
        await handleCognitoPasswordSignInResult(response);
      } catch (error) {
        if (error instanceof Error) {
          dispatch({
            type: "LOGIN_FAILED",
            payload: { error },
          });
        }
      }
    },
    [dispatch, handleCognitoPasswordSignInResult]
  );

  const signInWithCognitoNewPassword = useCallback(
    async function signInWithCognitoNewPassword(email: string, password: string) {
      try {
        const response = await cognitoConfirmSignIn({
          challengeResponse: password,
        });

        await handleCognitoPasswordSignInResult(response, { email });
      } catch (e) {
        dispatch({
          type: "PASSWORD_SETUP_FAILED",
          payload: { error: e },
        });
      }
    },
    [dispatch, handleCognitoPasswordSignInResult]
  );

  const signInWithTwoFactorAuthCode = useCallback(
    async (email: string, code: string) => {
      try {
        const response = await cognitoConfirmSignIn({
          challengeResponse: code,
        });
        await handleCognitoPasswordSignInResult(response, { email });
      } catch (e) {
        dispatch({
          type: "2FA_FAILED",
          payload: { error: e },
        });
      }
    },
    [dispatch, handleCognitoPasswordSignInResult]
  );

  const signOutWithCognito = useCallback(async () => {
    onSignOut();
    await clearCurrentUserTokens();
    try {
      await cognitoSignOut();
      router.push("/sign-in");
    } catch (error) {
      const userId = getCurrentUserId();
      logError(error, { customMessage: `Unable to logout user ${userId} with Cognito completely` });
      // If cognito failed we won't get the automatic redirect. In this case want to manually direct user out since we
      // can't really do anything but investigate.
      await router.push("/sign-in");
    }
    dispatch({ type: "LOGOUT_DONE" });
  }, [dispatch, onSignOut, router]);

  const cancelResetPasswordFlow = useCallback(
    async function cancelTwoFactorAuth() {
      await cognitoSignOut();
      dispatch({ type: "PASSWORD_RESET_CANCELLED" });
    },
    [dispatch]
  );
  const cancelTwoFactorAuth = useCallback(
    async function cancelTwoFactorAuth() {
      await cognitoSignOut();
      dispatch({ type: "2FA_CANCELLED" });
    },
    [dispatch]
  );

  const cancelPasswordSetup = useCallback(
    async function cancelPasswordSetup() {
      await cognitoSignOut();
      dispatch({ type: "PASSWORD_SETUP_CANCELLED" });
    },
    [dispatch]
  );

  return {
    signInWithCognito,
    signInWithCognitoNewPassword,
    signInWithTwoFactorAuthCode,
    startPasswordResetFlowWithCognito,
    resetPasswordWithCognito,
    signOutWithCognito,
    cancelResetPasswordFlow,
    cancelTwoFactorAuth,
    cancelPasswordSetup,
    tryRestoreCognitoSession,
  };
}

import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function ClipboardIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 64 64" fill="none">
      <Circle
        opacity="0.4"
        cx="32"
        cy="32"
        r="32"
        fill={props.clearBackground ? "transparent" : color || colors.mint.light}
      />
      <Path
        d="M45 48C44.7348 48 44.4804 48.1054 44.2929 48.2929C44.1054 48.4804 44 48.7348 44 49C44 49.2652 43.8946 49.5196 43.7071 49.7071C43.5196 49.8946 43.2652 50 43 50H17C16.7348 50 16.4804 49.8946 16.2929 49.7071C16.1054 49.5196 16 49.2652 16 49V19C16 18.7348 16.1054 18.4804 16.2929 18.2929C16.4804 18.1054 16.7348 18 17 18H19C19.2652 18 19.5196 17.8946 19.7071 17.7071C19.8946 17.5196 20 17.2652 20 17C20 16.7348 19.8946 16.4804 19.7071 16.2929C19.5196 16.1054 19.2652 16 19 16H17C16.2044 16 15.4413 16.3161 14.8787 16.8787C14.3161 17.4413 14 18.2044 14 19V49C14 49.7957 14.3161 50.5587 14.8787 51.1213C15.4413 51.6839 16.2044 52 17 52H43C43.7956 52 44.5587 51.6839 45.1213 51.1213C45.6839 50.5587 46 49.7957 46 49C46 48.7348 45.8946 48.4804 45.7071 48.2929C45.5196 48.1054 45.2652 48 45 48Z"
        fill={color || colors.mint.darker}
      />
      <Path
        d="M41 18H43C43.2652 18 43.5196 18.1054 43.7071 18.2929C43.8946 18.4804 44 18.7348 44 19V21.076C44 21.3412 44.1054 21.5956 44.2929 21.7831C44.4804 21.9706 44.7348 22.076 45 22.076C45.2652 22.076 45.5196 21.9706 45.7071 21.7831C45.8946 21.5956 46 21.3412 46 21.076V19C46 18.2064 45.6856 17.4452 45.1256 16.8829C44.5656 16.3206 43.8056 16.0032 43.012 16H41C40.7348 16 40.4804 16.1054 40.2929 16.2929C40.1054 16.4804 40 16.7348 40 17C40 17.2652 40.1054 17.5196 40.2929 17.7071C40.4804 17.8946 40.7348 18 41 18Z"
        fill={color || colors.mint.darker}
      />
      <Path
        d="M48.084 31.3678L43.948 36.4218L41.916 33.9398C41.835 33.8319 41.7331 33.7415 41.6163 33.6741C41.4995 33.6066 41.3702 33.5635 41.2363 33.5473C41.1024 33.5311 40.9666 33.5422 40.8371 33.5799C40.7076 33.6175 40.587 33.681 40.4827 33.7664C40.3783 33.8519 40.2923 33.9575 40.2299 34.0771C40.1674 34.1966 40.1298 34.3276 40.1193 34.4621C40.1088 34.5965 40.1255 34.7317 40.1686 34.8595C40.2117 34.9873 40.2802 35.1051 40.37 35.2058L43.17 38.6318C43.2638 38.7461 43.3818 38.8381 43.5154 38.9014C43.6491 38.9646 43.7951 38.9974 43.943 38.9974C44.0908 38.9974 44.2369 38.9646 44.3705 38.9014C44.5042 38.8381 44.6222 38.7461 44.716 38.6318L49.626 32.6318C49.7177 32.5314 49.788 32.4134 49.8326 32.2849C49.8772 32.1565 49.8951 32.0203 49.8853 31.8847C49.8755 31.7491 49.8381 31.6169 49.7754 31.4962C49.7128 31.3755 49.6262 31.2689 49.5209 31.1828C49.4156 31.0968 49.2939 31.0331 49.1632 30.9957C49.0325 30.9582 48.8955 30.9479 48.7606 30.9652C48.6258 30.9825 48.4959 31.0271 48.3788 31.0963C48.2618 31.1655 48.1601 31.2579 48.08 31.3678H48.084Z"
        fill={color || colors.mint.darker}
      />
      <Path
        d="M45 25C43.0222 25 41.0888 25.5865 39.4443 26.6853C37.7998 27.7841 36.5181 29.3459 35.7612 31.1732C35.0043 33.0004 34.8063 35.0111 35.1922 36.9509C35.578 38.8907 36.5304 40.6725 37.9289 42.0711C39.3275 43.4696 41.1093 44.422 43.0491 44.8079C44.9889 45.1937 46.9996 44.9957 48.8268 44.2388C50.6541 43.4819 52.2159 42.2002 53.3147 40.5557C54.4135 38.9112 55 36.9778 55 35C54.9968 32.3488 53.9422 29.8071 52.0676 27.9324C50.1929 26.0578 47.6512 25.0032 45 25ZM45 43C43.4178 43 41.871 42.5308 40.5554 41.6518C39.2399 40.7727 38.2145 39.5233 37.609 38.0615C37.0035 36.5997 36.845 34.9911 37.1537 33.4393C37.4624 31.8874 38.2243 30.462 39.3432 29.3431C40.462 28.2243 41.8874 27.4624 43.4393 27.1537C44.9911 26.845 46.5997 27.0035 48.0615 27.609C49.5233 28.2145 50.7727 29.2398 51.6518 30.5554C52.5308 31.871 53 33.4177 53 35C53 37.1217 52.1572 39.1566 50.6569 40.6569C49.1566 42.1571 47.1217 43 45 43Z"
        fill={color || colors.mint.darker}
      />
      <Path
        d="M21 30H32C32.2652 30 32.5196 29.8946 32.7071 29.7071C32.8946 29.5196 33 29.2652 33 29C33 28.7348 32.8946 28.4804 32.7071 28.2929C32.5196 28.1054 32.2652 28 32 28H21C20.7348 28 20.4804 28.1054 20.2929 28.2929C20.1054 28.4804 20 28.7348 20 29C20 29.2652 20.1054 29.5196 20.2929 29.7071C20.4804 29.8946 20.7348 30 21 30Z"
        fill={color || colors.mint.darker}
      />
      <Path
        d="M21 36H30.2C30.4652 36 30.7196 35.8946 30.9071 35.7071C31.0946 35.5196 31.2 35.2652 31.2 35C31.2 34.7348 31.0946 34.4804 30.9071 34.2929C30.7196 34.1054 30.4652 34 30.2 34H21C20.7348 34 20.4804 34.1054 20.2929 34.2929C20.1054 34.4804 20 34.7348 20 35C20 35.2652 20.1054 35.5196 20.2929 35.7071C20.4804 35.8946 20.7348 36 21 36Z"
        fill={color || colors.mint.darker}
      />
      <Path
        d="M21 42H32C32.2652 42 32.5196 41.8946 32.7071 41.7071C32.8946 41.5196 33 41.2652 33 41C33 40.7348 32.8946 40.4804 32.7071 40.2929C32.5196 40.1054 32.2652 40 32 40H21C20.7348 40 20.4804 40.1054 20.2929 40.2929C20.1054 40.4804 20 40.7348 20 41C20 41.2652 20.1054 41.5196 20.2929 41.7071C20.4804 41.8946 20.7348 42 21 42Z"
        fill={color || colors.mint.darker}
      />
      <Path
        d="M22 22.0004H38C38.2652 22.0004 38.5196 21.8951 38.7071 21.7075C38.8946 21.52 39 21.2656 39 21.0004V19.0004C38.9982 17.8337 38.5893 16.7041 37.8437 15.8066C37.0981 14.9092 36.0627 14.3001 34.916 14.0844C34.7028 12.9363 34.0946 11.899 33.1966 11.1524C32.2987 10.4058 31.1678 9.99707 30 9.99707C28.8322 9.99707 27.7013 10.4058 26.8034 11.1524C25.9054 11.899 25.2972 12.9363 25.084 14.0844C23.9373 14.3001 22.9019 14.9092 22.1563 15.8066C21.4107 16.7041 21.0018 17.8337 21 19.0004V21.0004C21 21.2656 21.1054 21.52 21.2929 21.7075C21.4804 21.8951 21.7348 22.0004 22 22.0004ZM23 19.0004C23 18.2048 23.3161 17.4417 23.8787 16.8791C24.4413 16.3165 25.2044 16.0004 26 16.0004C26.2652 16.0004 26.5196 15.8951 26.7071 15.7075C26.8946 15.52 27 15.2656 27 15.0004C27 14.2048 27.3161 13.4417 27.8787 12.8791C28.4413 12.3165 29.2044 12.0004 30 12.0004C30.7956 12.0004 31.5587 12.3165 32.1213 12.8791C32.6839 13.4417 33 14.2048 33 15.0004C33 15.2656 33.1054 15.52 33.2929 15.7075C33.4804 15.8951 33.7348 16.0004 34 16.0004C34.7956 16.0004 35.5587 16.3165 36.1213 16.8791C36.6839 17.4417 37 18.2048 37 19.0004V20.0004H23V19.0004Z"
        fill={color || colors.mint.darker}
      />
    </Svg>
  );
}

import * as React from "react";
import Svg, { Path } from "react-native-svg";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from "./";

export default function CarbonLogo(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;

  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5622 17.2856C19.6661 17.2856 20.561 16.3902 20.561 15.2856C20.561 14.1811 19.6661 13.2856 18.5622 13.2856C17.4583 13.2856 16.5635 14.1811 16.5635 15.2856C16.5635 16.3902 17.4583 17.2856 18.5622 17.2856Z"
        fill={color || colors.green.DEFAULT}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99874 10.7144C7.10261 10.7144 7.99748 9.81892 7.99748 8.71436C7.99748 7.60979 7.10261 6.71436 5.99874 6.71436C4.89487 6.71436 4 7.60979 4 8.71436C4 9.81892 4.89487 10.7144 5.99874 10.7144Z"
        fill={color || colors.blue.DEFAULT}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5622 10.143C19.3507 10.143 19.9899 9.50344 19.9899 8.71446C19.9899 7.92548 19.3507 7.28589 18.5622 7.28589C17.7737 7.28589 17.1345 7.92548 17.1345 8.71446C17.1345 9.50344 17.7737 10.143 18.5622 10.143Z"
        fill={color || colors.mint.DEFAULT}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99871 16.7142C6.7872 16.7142 7.42638 16.0746 7.42638 15.2856C7.42638 14.4966 6.7872 13.8571 5.99871 13.8571C5.21023 13.8571 4.57104 14.4966 4.57104 15.2856C4.57104 16.0746 5.21023 16.7142 5.99871 16.7142Z"
        fill={color || colors.yellow.DEFAULT}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2805 7C13.3843 7 14.2792 6.10457 14.2792 5C14.2792 3.89543 13.3843 3 12.2805 3C11.1766 3 10.2817 3.89543 10.2817 5C10.2817 6.10457 11.1766 7 12.2805 7Z"
        fill={color || colors.purple.DEFAULT}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2805 21C13.3843 21 14.2792 20.1046 14.2792 19C14.2792 17.8954 13.3843 17 12.2805 17C11.1766 17 10.2817 17.8954 10.2817 19C10.2817 20.1046 11.1766 21 12.2805 21Z"
        fill={color || colors.red.DEFAULT}
      />
    </Svg>
  );
}

import React from "react";
import {Text} from "react-native";
import colors from "../colors";
import {filterA11yRoleByPlatform} from "./a11y";
import {LegacyStyleProps, TypographyBaseProps, TypographyWeight} from "./types";

// the parent is responsible for deciding what to do with the
// weight, so the base isn't concerned about it.
export type BaseHeadingProps = TypographyBaseProps & {
  level: 0 | 1 | 2 | 3 | 4;
  accessibilityLevel?: number;
};
// this is the type for the publicly exported component. Since it'll be
// shared among pro/consumer, it can live in base.
export type HeadingProps = BaseHeadingProps & LegacyStyleProps & TypographyWeight;

export default function Heading({
  accessibilityLevel: overrideaccessibilityLevel,
  accessibilityRole = "header",
  children,
  level,
  style,
  color = colors.gray[1000],
  ...rest
}: BaseHeadingProps) {
  // use the accessibilityLevel prop or the default for the given level
  const accessibilityLevel = overrideaccessibilityLevel || level;

  return (
    <Text
      {...rest}
      // @ts-expect-error TS2322
      accessibilityLevel={
        // only headings and lists can have a level https://www.w3.org/TR/wai-aria-1.2/#aria-level
        // we care about this because there may be use cases to have body text _look_ like a header/larger
        // than normal, so someone may be using the Heading component in a non-heading use case
        // @ts-expect-error TS2322
        accessibilityRole === "header" || accessibilityRole === "listitem"
          ? accessibilityLevel
          : undefined
      }
      accessibilityRole={filterA11yRoleByPlatform(accessibilityRole)}
      style={[style, {color}]}
    >
      {children}
    </Text>
  );
}

import React, { useCallback, useRef } from "react";
import { View } from "react-native";

import { tailwind } from "src/foundations/styles";
import useKeyDown from "src/ui/hooks/useKeydown";

export const MenuList: React.FC = (props) => {
  const ref = useRef<View | null>(null);

  // TODO: Keyboard interaction: https://www.w3.org/TR/wai-aria-practices/#keyboard-interaction-12
  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    const key = event.key;

    if (key === "ArrowDown") {
      // Prevent scroll of the page
      event.preventDefault();
    } else if (key === "ArrowUp") {
      event.preventDefault();
    } else if (key === "Home") {
      event.preventDefault();
    } else if (key === "End") {
      event.preventDefault();
    }
  }, []);

  useKeyDown(ref, handleKeyPress);

  // TODO: accessibilityActiveDescendant. Doesn't seem to exist per TS in spite of the docs.
  // https://www.w3.org/TR/wai-aria-practices/#wai-aria-roles-states-and-properties-13
  // https://necolas.github.io/react-native-web/docs/accessibility/#accessibility-props-api
  return (
    <View ref={ref} accessibilityRole="menu" style={[tailwind("max-h-80"), { overflowY: "auto" }]}>
      {props.children}
    </View>
  );
};

export default MenuList;

import {
  AuthSdkError,
  isRefreshTokenInvalidError,
  OAuthResponse,
  OktaAuth,
  OktaAuthOptions,
  postRefreshToken,
  RefreshToken,
  TokenParams,
} from "@okta/okta-auth-js";
import isEmpty from "lodash/isEmpty";

import { GetMyUserIdResponse, getMyUserId } from "src/auth/api/getMyUserId";
import { logError } from "src/common/utils/reporting";

import {
  getCurrentUserId,
  getRefreshTokenForCurrentUser,
  setRefreshToken,
  setTokens,
} from "./userToken";

let oktaAuthClient: OktaAuth | undefined;

export type OktaLoginResponse = {
  accessToken?: string;
  refreshToken?: string;
};

export const oktaConfig: OktaAuthOptions = {
  clientId: process.env.NEXT_PUBLIC_OKTA_CLIENT_ID,
  redirectUri: global.location?.origin,
  postLogoutRedirectUri: global.location?.origin,
  issuer: process.env.NEXT_PUBLIC_OKTA_ISSUER_URL,
  scopes: ["openid", "offline_access"],
  pkce: true,
};

function setOktaAuthClient(oktaAuth: OktaAuth): void {
  oktaAuthClient = oktaAuth;
}

export function getOktaAuthClient(): OktaAuth {
  if (oktaAuthClient !== undefined) return oktaAuthClient;
  const oktaClient = initializeOkta();
  setOktaAuthClient(oktaClient);
  return oktaClient;
}

function initializeOkta(): OktaAuth {
  return new OktaAuth(oktaConfig);
}

export function isPopupBlockedOrClosed(error: unknown): boolean {
  return (
    error instanceof AuthSdkError &&
    error.errorCode === "INTERNAL" &&
    error.errorSummary === "Unable to parse OAuth flow response"
  );
}

/**
 * Example: Okta popup is left open for more than 2 minutes
 */
export function isOktaFlowTimedOutError(error: unknown): boolean {
  return (
    error instanceof AuthSdkError &&
    error.errorCode == "INTERNAL" &&
    error.errorSummary === "OAuth flow timed out"
  );
}

export async function refreshOktaToken() {
  const refreshToken = getRefreshTokenForCurrentUser();
  if (refreshToken === null) return;

  const response = await requestNewToken(refreshToken);
  if (response === undefined) return;

  const userId = getCurrentUserId();
  if (userId === null) {
    const userIdResponse: GetMyUserIdResponse = await getMyUserId({
      customAuthToken: response.access_token ?? "",
    }).then((r) => r.json());
    setTokens(userIdResponse, response.access_token ?? "");
    setRefreshToken(userIdResponse, response.refresh_token ?? "");
  } else {
    setTokens(userId, response.access_token ?? "");
    setRefreshToken(userId, response.refresh_token ?? "");
  }
}

/**
 * Reach out to Okta to get a new access token based on the refresh token that we have.
 */
export async function requestNewToken(refreshToken: string): Promise<OAuthResponse | undefined> {
  const client = getOktaAuthClient();
  const tokenParams: TokenParams = {
    pkce: true,
    scopes: oktaConfig.scopes,
    clientId: oktaConfig.clientId,
  };
  const refreshTokenParam: RefreshToken = {
    refreshToken,
    issuer: oktaConfig.issuer ?? "no-issuer",
    tokenUrl: `${oktaConfig.issuer}/v1/token`,
    // Just need _some_ value. Any value will work.
    expiresAt: 0,
    authorizeUrl: `${oktaConfig.issuer}/v1/authorize`,
    scopes: oktaConfig.scopes ?? [],
  };

  try {
    return await postRefreshToken(client, tokenParams, refreshTokenParam);
  } catch (error) {
    if (isRefreshTokenInvalidError(error)) return;
    logError(error, {
      customMessage: "Unexpected error while trying to refresh user token",
      extraData: {
        ...oktaConfig,
        // I'm not comfortable logging out refresh tokens.
        refreshTokenIsPresent: !isEmpty(refreshToken),
      },
    });
  }
}

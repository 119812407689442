export const DATE_FORMAT_MMDDYYYY_NO_SEPARATOR = "MMDDYYYY";

export const BILLS_FILTER_TYPE_DATE_RANGE_FORMAT = "YYYY-MM-DD";
export const paymentReportPaymentDateInputFormat = "YYYY-MM-DD";

export const MDY_ZERO_PADDED_WITH_SLASHES = "MM/DD/YYYY";

export const CENTS_IN_US_DOLLAR = 100;

export const DEFAULT_PAGE_SIZE = 25;

export const SORT_COLUMN_LIMIT = 3;

export const TEXT_INPUT_DEBOUNCE_DURATION_MS = 400;

export const MS_IN_A_DAY = 1000 * 60 * 60 * 24;

// TODO: Remove this const and use the process.env directly to remove indirections.
export const NEXT_PUBLIC_WEB_DOCTOR_DEEPLINK_PREFIX =
  process.env.NEXT_PUBLIC_WEB_DOCTOR_DEEPLINK_PREFIX;

/**
 * String that implies that a text value is being loaded. This is to know if a text valu is missing
 * (`undefined | null`) or simply being fetched.
 */
export const LOADING_SYMBOL = Symbol("Loading");

export const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

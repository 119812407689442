import { useRouter } from "next/router";
import { useEffect } from "react";

import { useAuthContext } from "src/auth/hooks/useAuthContext";

type UserBoundaryProps = {
  redirect?: boolean;
};

export const UserBoundary: React.FC<UserBoundaryProps> = ({ children, redirect = true }) => {
  const { status } = useAuthContext();
  const router = useRouter();

  useEffect(() => {
    if (status === "unauthenticated" && redirect) {
      const redirectPath =
        router.asPath === "" || router.asPath === "/" ? undefined : `rdp=${router.asPath}`;
      const loginPageUrl = `/sign-in${redirectPath ? `?${redirectPath}` : ""}`;
      router.push(loginPageUrl);
    }

    if (status === "needPracticeDoctorSelection" && redirect) {
      const redirectPath =
        router.asPath === "" || router.asPath === "/" ? undefined : `rdp=${router.asPath}`;
      const loginPageUrl = `/sign-in${redirectPath ? `?${redirectPath}` : ""}`;
      router.push(loginPageUrl);
    }
  }, [status, router, redirect]);

  return status === "authenticated" ? <>{children}</> : null;
};

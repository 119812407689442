import {StyleSheet} from "react-native";
import {inter, interMedium, interSemiBold} from "./fonts";

// I'm not certain this font family will be "base" becasue we don't
// hae a full starting point on consumer. So if we need to move this
// to the pro/ dir later, we can.
export const interFontFamilies = StyleSheet.create({
  regular: {
    fontFamily: inter,
  },
  medium: {
    fontFamily: interMedium,
  },
  bold: {
    // we call this "bold" in figma, but the weight is 600,
    // which lines up with the "semibold" family
    // https://fonts.google.com/specimen/Inter
    fontFamily: interSemiBold,
  },
});

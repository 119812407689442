import { JwtPayload } from "jwt-decode";

export enum Groups {
  RCM_ADMINS = "RCMAdmins",
  RCM_MEMBERS = "RCMMembers",
  RCM_PRACTICE_ADMINS = "RCMPracticeAdmins",
  RCM_FRONT_DESK_STAFF = "RCMFrontDeskStaff",
  RCM_OCC_HEALTH = "RCMOccHealth",
}

export const rcmGroups = [
  Groups.RCM_ADMINS,
  Groups.RCM_MEMBERS,
  Groups.RCM_PRACTICE_ADMINS,
  Groups.RCM_FRONT_DESK_STAFF,
  Groups.RCM_OCC_HEALTH,
];

export enum Permissions {
  CREATE_QUEUE = "billinghub:create-queue",
  UPDATE_QUEUE = "billinghub:update-queue",
  LIST_TEAMS = "billinghub:list-teams",
}

export type AccessToken = JwtPayload & {
  groups?: string;
  permissions?: string;
  email?: string;
  carbonUserId?: string;
  practiceId?: string;
  practiceIds?: string;
};

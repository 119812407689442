import { StyleProp, TouchableOpacity, View, ViewStyle } from "react-native";

import { tailwind } from "../styles";

import { Paragraph } from ".";

// TODO: For web a11y, we need to accept an `href`. When provided, should result in an anchor tag.
export type Crumb = {
  title: string;
  onPress?: () => void;
};

export type Props = {
  crumbs: Array<Crumb>;
  // TODO: A11y props
  accessibilityLabel?: string;
  // TODO: A11y props
  accessibilityLabelledBy?: string;
  // TODO: A11y props
  accessibilityCurrent?: string;
  style?: StyleProp<ViewStyle>;
};

export default function Breadcrumb({
  crumbs,
  style,
  accessibilityLabel,
  accessibilityLabelledBy,
  accessibilityCurrent,
}: Props) {
  return (
    <View
      style={[tailwind("flex-row"), style]}
      // @ts-expect-error TS2769: No overload matches this call.
      accessibilityRole="navigation"
      accessibilityLabel={accessibilityLabel}
      accessibilityLabelledBy={accessibilityLabelledBy}
    >
      {crumbs.map(({ title, onPress }, index) => {
        const isCurrentPage = index === crumbs.length - 1;
        return (
          <View key={title} style={tailwind("flex-row")}>
            {onPress ? (
              <TouchableOpacity
                onPress={onPress}
                aria-current={isCurrentPage ? accessibilityCurrent : undefined}
              >
                <Paragraph level={2} weight={isCurrentPage ? "bold" : "normal"}>
                  {title}
                </Paragraph>
              </TouchableOpacity>
            ) : (
              <Paragraph level={2} weight={isCurrentPage ? "bold" : "normal"}>
                {title}
              </Paragraph>
            )}
            {index !== crumbs.length - 1 && <Paragraph level={2}> {">"} </Paragraph>}
          </View>
        );
      })}
    </View>
  );
}

import * as React from "react";
import { DimensionValue, StyleProp, View, ViewStyle } from "react-native";

import { tailwind } from "../styles";

type props = {
  style?: StyleProp<ViewStyle>;
  width?: DimensionValue;
  height?: DimensionValue;
};
export default function SkeletonBox({ style, width, height }: props) {
  return <View style={[tailwind("bg-gray-100"), { width, height }, style]} />;
}

type SkeletonBoxListProps = {
  itemsToRender?: 3 | 5 | 7;
};

export const SkeletonBoxList = ({ itemsToRender = 3 }: SkeletonBoxListProps) => {
  return (
    <>
      {[...Array(itemsToRender).keys()].map((i) => (
        <View
          key={i}
          style={tailwind(
            `bg-gray-100 h-14 w-full ${i === itemsToRender - 1 ? "mb-0" : "mb-6"} rounded-lg`
          )}
        />
      ))}
    </>
  );
};

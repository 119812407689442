import * as React from "react";
import Svg, { Path } from "react-native-svg";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from "./";

export default function Success(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;

  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 34 34" fill="none">
      <Path
        d="M17 33C25.8366 33 33 25.8366 33 17C33 8.16344 25.8366 1 17 1C8.16344 1 1 8.16344 1 17C1 25.8366 8.16344 33 17 33Z"
        stroke={color || colors.gray[800]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9.62939 17.1812L14.4435 23.065L24.3705 10.9316"
        stroke={color || colors.gray[800]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

import * as React from "react";
import Svg, { Path } from "react-native-svg";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function Close(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 36 36" fill="none">
      <Path
        d="M23.25 12.75L12.75 23.25"
        stroke={color || colors.gray[200]}
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.75 12.75L23.25 23.25"
        stroke={color || colors.gray[200]}
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

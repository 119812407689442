// utils/isVercelOrigin.ts

const vercelDomainSuffixes: string[] = ["carbonhealth.vercel.app", "billing.carbonhealth.dev"];

/**
 * Checks if the given origin matches any of the Vercel domain suffixes.
 *
 * @param origin - The origin to check.
 * @returns true if the origin ends with any of the Vercel domain suffixes, otherwise false.
 */
export const isVercelOrigin = (origin: string | undefined | null): boolean => {
  if (!origin) {
    return false; // Return false if origin is undefined or null
  }
  return vercelDomainSuffixes.some((suffix) => origin.endsWith(suffix));
};

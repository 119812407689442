import { VisitorInput } from "@ch/data/visitor";

import { USER_TIMEZONE } from "src/common/constants";
import { monolithFetcher } from "src/common/utils/fetching";
import { logError } from "src/common/utils/reporting";
import VisitorAPI from "src/common/utils/visitor";

import { noop } from "./index";

function isVisitorInput(data?: unknown): data is VisitorInput {
  return (
    typeof data === "object" &&
    data !== null &&
    "visitId" in data &&
    "visitorId" in data &&
    typeof data.visitId === "string" &&
    typeof data.visitorId === "string"
  );
}

export async function restoreVisitor(): Promise<void> {
  try {
    await VisitorAPI.restoreVisit();

    const URL = window.location.origin + window.location.pathname;
    const response = await monolithFetcher("/visit-tracking", {
      method: "POST",
      body: JSON.stringify({
        landingUrl: URL,
        timezone: USER_TIMEZONE,
      }),
    });

    const data = await response.json();
    if (isVisitorInput(data)) {
      await VisitorAPI.setVisit(data);
    } else {
      logError(new Error("Visit tracking response shape is broken"), {
        extraData: data,
      });
    }
  } catch (error: unknown) {
    noop();
  }
}

import { captureException, captureMessage } from "@sentry/nextjs";

import { getCurrentUserId } from "src/auth/utils/userToken";

type Options = {
  customMessage?: string;
  extraData?: Record<string, unknown>;
};

export function logError(error: unknown, options?: Options): void {
  captureException(error ?? new Error(options?.customMessage ?? "Unknown Error"), (scope) => {
    scope.addEventProcessor((event) => {
      event.message = options?.customMessage ?? event.message;
      return event;
    });
    scope.setUser({ id: getCurrentUserId() ?? undefined });
    scope.setContext("extra-data", options?.extraData ?? null);

    return scope;
  });
}

export function logInfo(options?: Options): void {
  captureMessage(
    options?.customMessage ?? "Unknown Message",

    (scope) => {
      scope.setUser({ id: getCurrentUserId() ?? undefined });
      scope.setContext("extra-data", options?.extraData ?? null);

      return scope;
    }
  );
}

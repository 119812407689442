import { memo } from "react";
import Svg, { Path } from "react-native-svg";

import colors from "../colors.json";

import { IconProps } from ".";

const Lock: React.FC<IconProps> = (props) => {
  const { color, size } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" title="Lock icon">
      <Path
        d="M15 9H6C5.44772 9 5 9.44772 5 10V17C5 17.5523 5.44772 18 6 18H15C15.5523 18 16 17.5523 16 17V10C16 9.44772 15.5523 9 15 9Z"
        stroke={color ?? colors.gray["800"]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M7 9V6.5C7 5.57174 7.36875 4.6815 8.02513 4.02513C8.6815 3.36875 9.57174 3 10.5 3C11.4283 3 12.3185 3.36875 12.9749 4.02513C13.6313 4.6815 14 5.57174 14 6.5V9"
        stroke={color ?? colors.gray["800"]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10.5 12.5V14.5"
        stroke={color ?? colors.gray["800"]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default memo(Lock) as typeof Lock;

import * as React from "react";
import Svg, { Path } from "react-native-svg";

import { IconProps, DISABLED_COLOR } from ".";

export default function Info(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 18 18" fill="none">
      <Path
        d="M10.5999 12.1996H10.0666C9.78366 12.1996 9.51235 12.0872 9.31231 11.8872C9.11227 11.6871 8.99989 11.4158 8.99989 11.1329V8.46627C8.99989 8.32482 8.9437 8.18917 8.84368 8.08915C8.74366 7.98913 8.60801 7.93294 8.46656 7.93294H7.93323"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.50843 4.55598C8.35952 4.55598 8.21394 4.60014 8.09012 4.68288C7.9663 4.76561 7.8698 4.8832 7.81281 5.02078C7.75582 5.15836 7.74091 5.30974 7.76996 5.4558C7.79901 5.60185 7.87072 5.73601 7.97602 5.8413C8.08132 5.9466 8.21549 6.01831 8.36154 6.04736C8.5076 6.07642 8.65899 6.0615 8.79657 6.00452C8.93415 5.94753 9.05175 5.85103 9.13448 5.72721C9.21722 5.60339 9.26138 5.45782 9.26138 5.30891C9.26138 5.10922 9.18205 4.91771 9.04085 4.77651C8.89964 4.63531 8.70813 4.55598 8.50843 4.55598Z"
        fill={color}
      />
      <Path
        d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

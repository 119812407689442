import { setContext } from "@apollo/client/link/context";

import { CarbonHeaders } from "src/auth/constants";
import { refreshToken } from "src/auth/utils/refreshToken";
import { isCurrentUserTokenExpiringSoon } from "src/auth/utils/userToken";

const authLink = setContext(async (request, previousContext) => {
  if (typeof previousContext?.customAuthToken === "string") {
    return {
      headers: {
        ...request.context?.headers,
        [CarbonHeaders.AUTHORIZATION]: `Bearer ${previousContext.customAuthToken}`,
      },
    };
  }

  if (!isCurrentUserTokenExpiringSoon()) return;

  try {
    const token = (await refreshToken()) ?? null;
    if (token === null || token === "") {
      return;
    }
    return {
      headers: {
        ...request.context?.headers,
        [CarbonHeaders.AUTHORIZATION]: `Bearer ${token}`,
      },
    };
  } catch (error) {
    return;
  }
});

export default authLink;

import jwtDecode, { JwtPayload } from "jwt-decode";

import { Permissions, Groups, AccessToken, rcmGroups } from "src/auth/types/userToken";

import { getTokenForCurrentUser, hasCognitoToken, hasOktaToken } from "./userToken";

export enum Action {
  CREATE_QUEUE,
  UPDATE_QUEUE,
  CREATE_PROCESSING_RULE,
  UPDATE_PROCESSING_RULE,
  APPROVE_PROCESSING_RULE,
  DELETE_PROCESSING_RULE,
  REORDER_PROCESSING_RULES,
  COPY_RULES,
  SEARCH,
  EXPORT,
  SETTINGS,
  VIEW_CATCH_ALL,
  VIEW_DEFERRALS,
  VIEW_ESCALATIONS,
  VIEW_SETTINGS_RULES,
  VIEW_SETTINGS_DEFERRAL_REASONS,
  VIEW_SETTINGS_ESCALATION_REASONS,
  VIEW_SETTINGS_OCC_HEALTH_ORGANIZATIONS,
  OCC_ADD_PAYMENT_DETAILS,
}

function getTokenPermissionsAndGroups(): { permissions: string[]; groups: string[] } {
  const token = getTokenForCurrentUser();
  if (hasCognitoToken()) {
    const decodedToken = token ? jwtDecode<AccessToken>(token) : undefined;
    return {
      permissions: decodedToken?.permissions?.split(",") ?? [],
      groups: decodedToken?.groups?.split(",") ?? [],
    };
  }
  if (hasOktaToken()) {
    const decodedToken = token
      ? jwtDecode<JwtPayload & { chtPermissions: string[] }>(token)
      : undefined;

    return {
      permissions: decodedToken?.chtPermissions ?? [],
      groups: [],
    };
  }
  return {
    permissions: [],
    groups: [],
  };
}

export function isUserMemberOfAnyGroup(groupsToCheck: string[]): boolean {
  const { groups } = getTokenPermissionsAndGroups();
  return groups.some((group) => groupsToCheck.includes(group));
}

export function isUserOnlyMemberOfGroups(groupsToCheck: string[]): boolean {
  const { groups } = getTokenPermissionsAndGroups();
  const excludedGroups = rcmGroups
    .filter((group) => !groupsToCheck.includes(group.toString()))
    .map((group) => group.toString());
  return (
    excludedGroups.every((excludedGroup) => !groups.includes(excludedGroup)) &&
    groupsToCheck.every((includedGroup) => groups.includes(includedGroup))
  );
}

export function isUserMemberOfGroup(groupToCheck: string): boolean {
  const { groups } = getTokenPermissionsAndGroups();
  return groups.includes(groupToCheck);
}

function isMemberOfAnyGroup(userGroups: string[], groupsToCheck: string[]): boolean {
  return userGroups.some((group) => groupsToCheck.includes(group));
}

export function canTakeAction(action: Action): boolean {
  const { groups, permissions } = getTokenPermissionsAndGroups();

  switch (action) {
    case Action.VIEW_SETTINGS_RULES:
    case Action.VIEW_SETTINGS_DEFERRAL_REASONS:
    case Action.VIEW_SETTINGS_ESCALATION_REASONS:
    case Action.SEARCH:
    case Action.EXPORT:
    case Action.VIEW_CATCH_ALL:
    case Action.VIEW_DEFERRALS:
    case Action.VIEW_ESCALATIONS:
      return isMemberOfAnyGroup(groups, [
        Groups.RCM_ADMINS,
        Groups.RCM_PRACTICE_ADMINS,
        Groups.RCM_MEMBERS,
      ]);
    case Action.SETTINGS:
    case Action.VIEW_SETTINGS_OCC_HEALTH_ORGANIZATIONS:
      return isMemberOfAnyGroup(groups, [
        Groups.RCM_ADMINS,
        Groups.RCM_PRACTICE_ADMINS,
        Groups.RCM_MEMBERS,
        Groups.RCM_OCC_HEALTH,
      ]);
    case Action.CREATE_QUEUE:
      return permissions.includes(Permissions.CREATE_QUEUE);
    case Action.UPDATE_QUEUE:
      return permissions.includes(Permissions.UPDATE_QUEUE);
    case Action.CREATE_PROCESSING_RULE:
    case Action.UPDATE_PROCESSING_RULE:
    case Action.APPROVE_PROCESSING_RULE:
    case Action.DELETE_PROCESSING_RULE:
    case Action.REORDER_PROCESSING_RULES:
    case Action.COPY_RULES:
      return groups.includes(Groups.RCM_ADMINS);
    case Action.OCC_ADD_PAYMENT_DETAILS:
      return isMemberOfAnyGroup(groups, [
        Groups.RCM_ADMINS,
        Groups.RCM_PRACTICE_ADMINS,
        Groups.RCM_MEMBERS,
        Groups.RCM_OCC_HEALTH,
      ]);
    default:
      return false;
  }
}

export function hasPermission(permission: Permissions): boolean {
  const { permissions } = getTokenPermissionsAndGroups();
  return permissions.includes(permission);
}

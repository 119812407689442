import { Router } from "next/router";

/**
 * Narrows down a query value from type `string | string[] | undefined` to just `string | undefined`
 * by converting an array of string into a string.
 * https://stackoverflow.com/questions/66121256/why-are-next-js-req-query-objects-values-of-type-string-string
 */
export function convertQueryValueToString(value: Router["query"][string]): string | undefined {
  return Array.isArray(value) ? value.join(",") : value;
}

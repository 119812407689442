import React, {useMemo} from "react";
import {StyleSheet} from "react-native";
import {Paragraph} from "../../base/typography";
import type {ParagraphProps} from "../../base/typography/Paragraph";
import {interFontFamilies} from "../../base/typography/styles";

export function ProParagraph({
  weight = "regular",
  level = 1,
  legacyStyle,
  ...props
}: ParagraphProps) {
  const style = useMemo(() => {
    return [paragraphStyles[level], interFontFamilies[weight], legacyStyle];
  }, [level, weight, legacyStyle]);
  return <Paragraph style={style} {...props} />;
}

export const paragraphStyles = StyleSheet.create({
  1: {
    fontSize: 16,
    lineHeight: 24,
  },
  2: {
    fontSize: 14,
    lineHeight: 20,
  },
  3: {
    fontSize: 12,
    lineHeight: 20,
  },
});

export default ProParagraph;

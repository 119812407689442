import React, { memo } from "react";
import Svg, { Path } from "react-native-svg";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

const KeyboardReturnKey: React.FC<IconProps> = (props) => {
  const color = props.disabled ? DISABLED_COLOR : props.color ?? colors.gray[800];
  return (
    <Svg width={props.size} height={props.size} fill="none" viewBox="0 0 16 16">
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 9h5a3 3 0 0 0 3-3V4.5"
      />
      <Path stroke={color} strokeLinecap="round" strokeLinejoin="round" d="M6.5 6.5 4 9l2.5 2.5" />
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13 1H3a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h10a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Z"
      />
    </Svg>
  );
};

export default memo(KeyboardReturnKey) as typeof KeyboardReturnKey;

import { gql } from "@apollo/client";
import jwtDecode from "jwt-decode";
import Router, { useRouter } from "next/router";
import { useEffect, createContext, useCallback, useReducer } from "react";

import { AccessToken, Groups } from "src/auth/types/userToken";
import {
  getStoredPracticeId,
  setLocalPracticeId,
  setStoredPracticeId,
} from "src/auth/utils/practice";
import { refreshToken } from "src/auth/utils/refreshToken";
import { throwIfUnauthenticated } from "src/auth/utils/throwIfUnauthenticated";
import {
  clearCurrentUserTokens,
  getTokenForCurrentUser,
  hasCognitoToken,
  isCurrentUserTokenExpiringSoon,
  hasOktaToken,
  getPracticeIdsFromAccessToken,
  setTokens,
  setRefreshToken,
} from "src/auth/utils/userToken";
import { noop } from "src/common/utils";
import { convertQueryValueToString } from "src/common/utils/convertQueryValueToString";
import { client } from "src/common/utils/graphql";
import { logError } from "src/common/utils/reporting";
import { restoreVisitor } from "src/common/utils/restoreVisitor";
import { isBillingUser } from "src/graphql/utils/typeGuards";

import {
  AuthProviderDoctorsQuery,
  AuthProviderDoctorsQueryVariables,
} from "./__generated__/AuthProvider.type";
import {
  authProviderDefaultState,
  AuthProviderState,
  authProviderStoreReducer,
} from "./authProviderStore";
import useCognito, { UseCognitoData } from "./useCognito";
import useOkta, { UseOktaData } from "./useOkta";

type AuthProviderProps = {
  onSignOut?: () => void;
};

type AuthContextOktaData = Omit<UseOktaData, "tryRestoreOktaSession">;
type AuthContextCognitoData = Omit<UseCognitoData, "tryRestoreCognitoSession">;

export type AuthContextData = AuthProviderState &
  AuthContextOktaData &
  AuthContextCognitoData & {
    /**
     * Finalize sign in process by redirecting user into the app. This is separate call from
     * `signIn()` because signing-in could result in an half-way success state: "needTwoFactorAuth".
     * If called before user is fully authenticated, it will be a noop.
     */
    letUserIn: () => void;
    // should be only used on login flow
    selectPracticeOnLogin: (practiceId: string) => Promise<void>;
    cancelPracticeDoctorSelection: () => Promise<void>;
  };

export const AuthContext = createContext<AuthContextData | undefined>(undefined);

const authProviderDoctorsQuery = gql`
  query AuthProviderDoctors {
    me {
      ... on BillingUser {
        id
        allowedPractices {
          id
          name
        }
        myAccessibleDoctors {
          id
          practice {
            id
          }
        }
      }
    }
  }
`;

const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

async function redirectUserToPageWithPracticePrefixIfNecessary(practiceId: string) {
  const { practiceId: practiceIdParam } = Router.query;
  if (
    practiceIdParam === undefined &&
    (Router.asPath.startsWith("/bills") ||
      Router.asPath.startsWith("/queues") ||
      Router.asPath.startsWith("/settings"))
  ) {
    await Router.replace(`/${practiceId}${Router.asPath}`);
  }
  return;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, onSignOut = noop }) => {
  const router = useRouter();
  const [state, dispatch] = useReducer(authProviderStoreReducer, authProviderDefaultState);

  const handleStartPracticeInitFlow = useCallback(
    async (props: {
      accessToken?: string;
      mountedRef: { mounted: boolean };
      restoringExistingSession: boolean;
      refreshToken?: string;
    }) => {
      await restoreVisitor();
      const { accessToken, mountedRef, restoringExistingSession } = props;
      if (!accessToken) {
        mountedRef.mounted &&
          dispatch({
            type: restoringExistingSession ? "GET_STORED_TOKEN_FAILED" : "LOGIN_FAILED",
            payload: restoringExistingSession
              ? undefined
              : { error: new Error("Authentication failed. No access token found.") },
          });
        return;
      }
      const decodedAccessToken = jwtDecode<AccessToken>(accessToken);
      const email = decodedAccessToken.email ?? "";
      const practiceIds = getPracticeIdsFromAccessToken(accessToken);
      if (practiceIds.length === 0) {
        mountedRef.mounted &&
          dispatch({
            type: "ERROR",
            payload: {
              error: new Error("Authentication failed. User is not assigned to any practice."),
            },
          });
        return;
      }

      if (email === undefined) {
        logError(new Error("Unable to get user info"), {
          customMessage: "Unable to get email from id token",
        });
        return;
      }
      const isOktaToken = decodedAccessToken.iss?.includes("okta") ?? false;
      const hasAccessToApp = isOktaToken
        ? true
        : decodedAccessToken.groups
            ?.split(",")
            ?.some(
              (group) =>
                group === Groups.RCM_ADMINS ||
                group === Groups.RCM_MEMBERS ||
                group === Groups.RCM_PRACTICE_ADMINS ||
                group === Groups.RCM_FRONT_DESK_STAFF ||
                group === Groups.RCM_OCC_HEALTH
            ) ?? false;

      if (!hasAccessToApp) {
        mountedRef.mounted &&
          dispatch({
            type: "LOGIN_FAILED",
            payload: { error: new Error("User is not assigned to application.") },
          });
        return;
      }

      const { data } = await client.query<
        AuthProviderDoctorsQuery,
        AuthProviderDoctorsQueryVariables
      >({
        query: authProviderDoctorsQuery,
        context: { customAuthToken: accessToken },
      });

      const { practiceId: practiceIdParam } = Router.query;
      const maybeParsedPracticeId = convertQueryValueToString(practiceIdParam) ?? "";
      const parsedPracticeId = uuidRegex.test(maybeParsedPracticeId)
        ? maybeParsedPracticeId
        : undefined;

      const me = isBillingUser(data?.me ?? undefined) ? throwIfUnauthenticated(data.me) : null;
      const userId = me?.id;

      // if user id is undefined, that means user has no accounts. They need to create one from the provider app
      // so, we can just open the practice selection
      if (userId === undefined) {
        mountedRef.mounted &&
          dispatch({
            type: "LOGIN_FAILED",
            payload: { error: new Error("Unable to get user info from the server") },
          });
        return;
      }

      // if user has no practices, we can't let them in
      if (me?.allowedPractices?.length === 0) {
        mountedRef.mounted &&
          dispatch({
            type: "LOGIN_FAILED",
            payload: { error: new Error("User is not assigned to a practice") },
          });
        return;
      }

      // if user has no doctors, we need to show the practice doctor selector
      if (me?.myAccessibleDoctors?.length === 0) {
        // let user set up a doctor
        mountedRef.mounted &&
          dispatch({
            type: "PRACTICE_DOCTOR_SELECTION_NEEDED",
            payload: {
              practiceDoctorSelectorUserId: userId,
              accessToken,
              refreshToken: props.refreshToken,
            },
          });
        return;
      }

      // if user has only one practice and one doctor, we can let them in automatically
      if (me?.myAccessibleDoctors?.length === 1 && me?.allowedPractices?.length === 1) {
        const practiceId = me?.myAccessibleDoctors?.[0]?.practice?.id ?? undefined;

        if (practiceId === undefined) {
          mountedRef.mounted &&
            dispatch({
              type: "LOGIN_FAILED",
              payload: { error: new Error("Could not find practice ID.") },
            });
          return;
        }

        if (parsedPracticeId !== undefined && parsedPracticeId !== practiceId) {
          mountedRef.mounted &&
            dispatch({
              type: "LOGIN_FAILED",
              payload: {
                error: new Error(
                  "User does not have access to the practice they are trying to access."
                ),
              },
            });
          return;
        }

        await setStoredPracticeId(practiceId, userId);
        setTokens(userId, accessToken);
        if (props.refreshToken) {
          setRefreshToken(userId, props.refreshToken);
        }
        await redirectUserToPageWithPracticePrefixIfNecessary(practiceId);
        await restoreVisitor();
        mountedRef.mounted && dispatch({ type: "LOGIN_DONE", payload: { userId } });
        return;
      }

      // if user's selected practice is not in the list of practices they have access to,
      const storedPracticeId = await getStoredPracticeId();
      const hasAccessToStoredPractice =
        me?.myAccessibleDoctors?.some((doctor) => doctor?.practice?.id === storedPracticeId) ??
        false;
      const hasAccessToParsedPractice =
        me?.myAccessibleDoctors?.some((doctor) => doctor?.practice?.id === parsedPracticeId) ??
        false;

      // on page refresh/load, if user has already logged in to a practice, let them in with that practice
      if (restoringExistingSession && storedPracticeId !== null && hasAccessToStoredPractice) {
        setTokens(userId, accessToken);
        if (props.refreshToken) {
          setRefreshToken(userId, props.refreshToken);
        }
        await setStoredPracticeId(storedPracticeId, userId);

        // if there's a practice ID in the URL, and it's different from the stored practice ID, and user has access to it
        // set the local practice ID to the parsed practice ID
        if (
          parsedPracticeId !== undefined &&
          parsedPracticeId !== storedPracticeId &&
          hasAccessToParsedPractice
        ) {
          setLocalPracticeId(parsedPracticeId);
          await redirectUserToPageWithPracticePrefixIfNecessary(parsedPracticeId);
        } else {
          await redirectUserToPageWithPracticePrefixIfNecessary(storedPracticeId);
        }

        await restoreVisitor();
        mountedRef.mounted && dispatch({ type: "LOGIN_DONE", payload: { userId } });
      } else {
        // show the practice doctor selector
        mountedRef.mounted &&
          dispatch({
            type: "PRACTICE_DOCTOR_SELECTION_NEEDED",
            payload: {
              practiceDoctorSelectorUserId: userId,
              accessToken,
              refreshToken: props.refreshToken,
            },
          });
      }
    },
    []
  );

  const selectPracticeOnLogin = useCallback(
    async (practiceId: string) => {
      if (state.practiceDoctorSelectorUserId === undefined) {
        dispatch({
          type: "PRACTICE_DOCTOR_SELECTION_FAILED",
          payload: {
            error: new Error("User ID is not found."),
          },
        });
        return;
      }

      await client.clearStore();
      await setStoredPracticeId(practiceId, state.practiceDoctorSelectorUserId);
      await handleStartPracticeInitFlow({
        restoringExistingSession: true,
        mountedRef: { mounted: true },
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
      }).catch((error) => {
        dispatch({ type: "ERROR", payload: { error } });
      });
    },
    [
      state.accessToken,
      state.refreshToken,
      state.practiceDoctorSelectorUserId,
      handleStartPracticeInitFlow,
    ]
  );

  const { tryRestoreOktaSession, ...oktaHelpers } = useOkta({
    dispatch,
    onStartPracticeInitFlow: handleStartPracticeInitFlow,
    onSignOut,
  });

  const { tryRestoreCognitoSession, ...cognitoHelpers } = useCognito({
    dispatch,
    onStartPracticeInitFlow: handleStartPracticeInitFlow,
    onSignOut,
  });

  const cancelPracticeDoctorSelection = useCallback(async () => {
    await clearCurrentUserTokens();
    onSignOut();
    dispatch({ type: "2FA_CANCELLED" });
  }, [onSignOut]);

  // Check if the user is already authenticated on page load, if so, restore the session or flows
  useEffect(() => {
    let mounted = true;

    async function restoreSession() {
      const token = getTokenForCurrentUser();
      if (token === null || token === "") {
        // No token found, user is not authenticated
        dispatch({ type: "GET_STORED_TOKEN_FAILED" });
        return;
      }

      if (isCurrentUserTokenExpiringSoon()) {
        try {
          // if token is expiring soon, refresh it
          const token = await refreshToken();
          if (!token) {
            // if token is not refreshed, user is not authenticated
            mounted && dispatch({ type: "GET_STORED_TOKEN_FAILED" });
            return;
          }
        } catch {
          mounted && dispatch({ type: "GET_STORED_TOKEN_FAILED" });
          return;
        }
      }

      if (hasOktaToken()) {
        await tryRestoreOktaSession();
        return;
      }

      if (hasCognitoToken()) {
        await tryRestoreCognitoSession();
        return;
      }

      // no branch executed, user is not authenticated
      dispatch({ type: "GET_STORED_TOKEN_FAILED" });
      return;
    }

    restoreSession().catch((error) => mounted && dispatch({ type: "ERROR", payload: { error } }));

    return () => {
      mounted = false;
    };
  }, [tryRestoreCognitoSession, tryRestoreOktaSession]);

  useEffect(() => {
    if (state.status === "unauthenticated") {
      clearCurrentUserTokens();
    }
  }, [state.status]);

  const letUserIn = useCallback(async () => {
    if (state.status !== "authenticated") return;
    const redirectPath = router.query.rdp || "/";
    await router.replace(redirectPath as string);
  }, [router, state.status]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        letUserIn,
        cancelPracticeDoctorSelection,
        selectPracticeOnLogin,
        ...cognitoHelpers,
        ...oktaHelpers,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

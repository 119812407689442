import React from "react";
import { View } from "react-native";

import SkeletonBox from "./SkeletonBox";

export function TableSkeleton(): JSX.Element {
  return (
    <View>
      <TableColumnHeaderSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
    </View>
  );
}

function TableColumnHeaderSkeleton(): JSX.Element {
  return <SkeletonBox height={64} />;
}

function TableRowSkeleton(): JSX.Element {
  return <SkeletonBox height={52} />;
}

export default TableSkeleton;

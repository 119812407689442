import { gql, useQuery } from "@apollo/client";
import { createContext, useContext, useMemo } from "react";

import { useAuthContext } from "src/auth/hooks/useAuthContext";
import { User } from "src/common/types/user";

import { UserQuery } from "./__generated__/index.type";

export type UserContextData = {
  user?: User;
};

const getCurrentUser = gql`
  query User {
    billingHubUser @rest(path: "/doc/doctors/me") {
      id
      firstName
      lastName
      userEmail
    }
  }
`;

const defaultUserContext: UserContextData = {
  user: undefined,
};

export const UserContext = createContext<UserContextData>(defaultUserContext);

export function useUserContext(): UserContextData {
  return useContext(UserContext);
}
export const UserProvider: React.FC<unknown> = (props) => {
  const { userId } = useAuthContext();
  const { data } = useQuery<UserQuery>(getCurrentUser, {
    skip: !userId,
  });

  const value: UserContextData = useMemo(
    () => ({
      user: data?.billingHubUser?.userEmail
        ? {
            email: data.billingHubUser.userEmail,
            ...data.billingHubUser,
          }
        : undefined,
    }),
    [data?.billingHubUser]
  );

  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
};
